import { React, useCallback, useMemo } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import MaterialUnitsSelector from '../../common/inputs/MaterialUnitsSelector';
import { CATEGORIES, costCodeCategoryMap, categoriesWithHours } from '../projectHelpers';
import { currencyFormatter, currencyParser } from '../../helpers/inputParsers';
import VerticallyCenteredNumberInput from '../../common/inputs/VerticallyCenteredNumberInput';
import { getMaterialCostColumns, getEquipmentCostColumns } from '../ProjectProgress/ProgressColumns';
import { isExpandableCostcode } from '../../helpers/costcodeHelpers';

function ProjectBudgetTrackingUnphased({
  isNotDisplay,
  onEditCostcodeEstimateChanged,
  unphasedCostcodes,
  categories,
  useBuildEstimate,
}) {
  const onValueChanged = useCallback((
    record,
    key,
    newValue,
  ) => (value) => {
    let valueToUse = value;
    if (key === 'estimatedCost' && Number.isNaN(valueToUse)) valueToUse = 0;
    const updateObj = { [key]: newValue || valueToUse };
    if (!!useBuildEstimate && key === 'hours') {
      const { hourlyWage = 0 } = record;
      const calculatedEstimatedCost = (hourlyWage * (newValue || value)) || 0;
      updateObj.estimatedCost = calculatedEstimatedCost;
    }
    onEditCostcodeEstimateChanged(record, updateObj);
  }, [onEditCostcodeEstimateChanged, useBuildEstimate]);

  const getUnphasedCols = useCallback((type) => {
    const inputOptions = {
      formatter: currencyFormatter,
      parser: currencyParser,
    };

    const cols = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Cost',
        dataIndex: 'estimatedCost',
        key: 'estimatedCost',
        width: 100,
        render: (estimatedCost, record) => {
          if (isNotDisplay) {
            return (
              <VerticallyCenteredNumberInput
                min={0}
                value={estimatedCost}
                onChange={onValueChanged(record, 'estimatedCost')}
                style={{ width: 150 }}
                {...inputOptions}
                asFloat
              />
            );
          }

          const ourNumber = !Number.isNaN(estimatedCost) ? estimatedCost : '';
          return ourNumber ? inputOptions.formatter(ourNumber) : ourNumber;
        },
      },
    ];
    if (categoriesWithHours.has(type)) {
      cols.push({
        title: 'Hours',
        dataIndex: 'hours',
        key: 'hours',
        width: 100,
        render: (hours, record) => {
          if (isNotDisplay) {
            return (
              <VerticallyCenteredNumberInput
                min={0}
                defaultValue={hours}
                onChange={onValueChanged(record, 'hours')}
                style={{ width: 150 }}
                asFloat
              />
            );
          }

          return Number.isInteger(hours) ? hours : '-';
        },
      });
    }
    if (type === 'Material') {
      cols.push({
        title: 'Estimated Quantity',
        dataIndex: 'estimatedQuantity',
        key: 'estimatedQuantity',
        width: 150,
        render: (_, record) => {
          if (!isNotDisplay) return record.estimatedQuantity;
          if (!record.addQuantities && !record.estimatedQuantity) {
            return {
              children: (
                <BorderlessButton
                  title="Add Quantities"
                  style={{ width: 150 }}
                  onClick={onValueChanged(record, 'addQuantities', true)}
                />
              ),
              props: { colSpan: 2 },
            };
          }
          return (
            <VerticallyCenteredNumberInput
              min={0}
              defaultValue={record.estimatedQuantity}
              onChange={onValueChanged(record, 'estimatedQuantity')}
              asFloat
            />
          );
        },
      });

      cols.push({
        title: 'Units',
        dataIndex: 'units',
        key: 'units',
        width: 150,
        render: (_, record) => {
          if (!record.addQuantities && !record.estimatedQuantity) return null;
          if (isNotDisplay) {
            return (
              <MaterialUnitsSelector
                value={record.units}
                onSelect={onValueChanged(record, 'units')}
              />
            );
          }
          return record.units;
        },
      });
    }
    return cols;
  }, [
    isNotDisplay,
    onValueChanged,
  ]);

  const expandableCostRowRender = useCallback((record) => {
    const costcodeCostEstimates = record.costUpdates ?? [];
    const getColumns = record.category === 'Material' ? getMaterialCostColumns : getEquipmentCostColumns;

    return (
      <Table
        columns={getColumns()}
        dataSource={costcodeCostEstimates}
        pagination={false}
        size="small"
        style={{ marginBottom: 10 }}
        rowKey="id"
      />
    );
  }, []);

  const categoryMap = useMemo(() => costCodeCategoryMap(unphasedCostcodes), [unphasedCostcodes]);

  const getCategoryTitle = useCallback((categoryName) => () => `Unphased ${categoryName} Cost Codes`);

  const unphasedTables = useMemo(() => CATEGORIES.map((categoryName) => {
    const {
      [categoryName]: {
        id: categoryId,
      } = {},
    } = categories;
    const {
      [categoryId]: categoryCostCodes = [],
    } = categoryMap;
    return (
      <Table
        key={categoryName}
        title={getCategoryTitle(categoryName)}
        size="small"
        columns={getUnphasedCols(categoryName)}
        pagination={false}
        dataSource={categoryCostCodes}
        style={{ marginBottom: 50 }}
        rowKey="id"
        expandable={{
          defaultExpandAllRows: false,
          expandedRowRender: (record) => {
            if (isExpandableCostcode(categoryName)) {
              return expandableCostRowRender(record);
            }
            return null;
          },
          rowExpandable: (record) => (
            (isExpandableCostcode(categoryName) && record?.costUpdates?.length)
          ),
        }}
      />
    );
  }), [
    categoryMap,
    categories,
    getUnphasedCols,
    expandableCostRowRender,
  ]);

  return unphasedTables;
}

/* eslint-disable react/forbid-prop-types */
ProjectBudgetTrackingUnphased.propTypes = {
  isNotDisplay: PropTypes.bool.isRequired,
  onEditCostcodeEstimateChanged: PropTypes.func.isRequired,
  unphasedCostcodes: PropTypes.array.isRequired,
  categories: PropTypes.object.isRequired,
};

export default ProjectBudgetTrackingUnphased;

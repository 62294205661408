import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';

import OnTraccrEmpty from '../common/OnTraccrEmpty';

import SubtasksHeader from './SubtasksHeader';
import SubtaskDrawer from './SubtaskDrawer';
import SubtaskTable from './SubtaskTable';

import { getSubTasks } from './state/subtasks.actions';

import { PRIORITY_MAP } from './subtask.constants';

import { includesTerm } from '../helpers/helpers';

export default function Subtasks({
  query,
}) {
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [editMode, setEditMode] = useState(false);

  const onAddClicked = () => setDrawerOpen(true);
  const onDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedTaskId();
    setEditMode(false);
  };

  const subtasks = useSelector((state) => state.subtasks.subtasks);

  const onTaskEdit = useCallback((taskId) => {
    setSelectedTaskId(taskId);
    setDrawerOpen(true);
    setEditMode(true);
  }, []);

  const onTaskClicked = useCallback((task) => {
    setSelectedTaskId(task.id);
    setDrawerOpen(true);
  }, []);

  const subtaskList = useMemo(() => {
    const list = Object.values(subtasks);
    // Default sort to highest priority
    list.sort((a, b) => PRIORITY_MAP[b.priority] - PRIORITY_MAP[a.priority]);
    return list;
  }, [subtasks]);

  const filteredList = useMemo(() => {
    if (!searchTerm || searchTerm.length === 0) return subtaskList;
    return subtaskList.filter((st) => (
      includesTerm(st.title ?? '', searchTerm) || includesTerm(st.description ?? '', searchTerm)
    ));
  }, [subtaskList, searchTerm]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getSubTasks(query));
      setLoading(false);
    };
    if (query) load();
  }, [query]);

  let body;

  if (subtaskList.length === 0 || loading) {
    body = (
      <Row justify='center' align='middle' style={{ width: '100%', height: '100%' }}>
        <Col>
          {loading ? <Spin/> : <OnTraccrEmpty/>}
        </Col>
      </Row>
    );
  } else {
    body = (
      <SubtaskTable
        subtasks={filteredList}
        onTaskClick={onTaskClicked}
        onTaskEdit={onTaskEdit}
      />
    );
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <SubtasksHeader
        onAddClicked={onAddClicked}
        onSearch={setSearchTerm}
      />
      {body}
      <SubtaskDrawer
        visible={drawerOpen}
        onClose={onDrawerClose}
        query={query}
        selectedTaskId={selectedTaskId}
        editMode={editMode}
        onEditModeChange={setEditMode}
      />
    </div>
  );
}

import React from 'react';
import { Row, Col,Typography, } from 'antd';
import ProfileCircle from './profile/ProfileCircle';

import colors from '../constants/Colors';
import { initialsFromName } from '../helpers/users';

const scaleFont = (word,fontSize) => {
  if(word.length > 20) return fontSize * 0.66;
  if(word.length > 15) return fontSize * 0.75;
  if(word.length > 13) return fontSize * 0.90;
  return fontSize;
};

const ProfileAvatar = ({
  name = '',
  role,
  style,
  textColor = colors.ONTRACCR_GRAY,
  fontSize = 16,
  nameStyle = {},
  roleStyle = {},
  avatarRadius = 17,
  maxWidth = 200,
}) => {
  const initials = initialsFromName(name);

  const avatarDiameter =  avatarRadius * 2;
  
  return (
    <Row 
      align='middle'
      justify='start'
      style={{
        width:'100%',
        ...style,
        maxHeight:avatarDiameter * 1.5,
        textOverflow: 'ellipsis',
        marginLeft:0,
        marginRight:0,
      }}>

      <Col 
        flex={`${25 + avatarDiameter}px`}
        style={{
          paddingLeft:15,
          paddingRight:10,
      }}>
        <ProfileCircle initials={initials || ''} radius={avatarRadius}/>
      </Col>

      <Col flex='auto' style={{maxWidth:maxWidth - 25 - avatarDiameter - 15}}>
        <Row
          align='bottom'
          style={{
            color:textColor,
            fontSize,
            width:'100%',
          }}>
       
            <Typography.Text style={{ 
                color:textColor,
                fontFamily:'roboto-bold',
                width:'100%',
                fontSize:scaleFont(name,fontSize),
                ...nameStyle,
                overflowWrap:'break-word',
              }}>
              {name}
            </Typography.Text>
          </Row>
          <Row style={{
            color:textColor,
            fontSize:fontSize * 0.75,
            fontFamily:'roboto-light',
            ...roleStyle,
          }}>
            {role}
          </Row>
      </Col>
    </Row>
)};

export default ProfileAvatar;
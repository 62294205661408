import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Row, Col, Typography, Checkbox,
} from 'antd';
import {
  DeleteOutlined,
  DragOutlined,
} from '@ant-design/icons';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import CalculationDropdown from './CalculationDropdown';

function TableFieldColumnCard({
  fieldId,
  sectionId,
  sections = [],
  id = '',
  orderIndex,
  name,
  isCalculation,
  isReadOnly,
  onDeleteClicked,
  configProps,
  setConfigProps,
}) {
  const {
    formulaMap = {},
    tagsMap = {},
  } = configProps;

  const formula = formulaMap[id];
  const tags = tagsMap[id] || [];
  const onFormulaChange = ((newFormula) => {
    setConfigProps({
      ...configProps,
      formulaMap: {
        ...formulaMap,
        [id]: newFormula,
      },
    });
  });

  const onTagsChange = ((newTags) => {
    setConfigProps({
      ...configProps,
      tagsMap: {
        ...tagsMap,
        [id]: newTags,
      },
    });
  });

  const onColumnAttributeChange = (key, value) => {
    setConfigProps((prevConfgProps) => {
      const { columns = [] } = prevConfgProps;
      const newColumns = [...columns];
      newColumns[orderIndex] = {
        ...newColumns[orderIndex],
        [key]: value,
      };

      return {
        ...prevConfgProps,
        columns: newColumns,
      };
    });
  };

  const onReadOnlyChange = (e) => {
    const { target: { checked = false } } = e;
    onColumnAttributeChange('isReadOnly', checked);
  };

  return (
    <Draggable draggableId={id.toString()} index={orderIndex}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          ref={innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProps}
          className="boards-status-card"
        >
          <Row justify="space-between" align="middle">
            <Col flex="18px">
              <Row justify="center" style={{ height: '100%', paddingRight: 5 }} align="middle">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <DragOutlined {...dragHandleProps} className="boards-status-dragger" />
              </Row>
            </Col>
            <Col flex="auto">
              <div
                style={{
                  marginLeft: 5,
                }}
              >
                <Typography.Text>
                  {name}
                </Typography.Text>
              </div>
            </Col>
            {isCalculation && (
            <Col flex="350px">
              <CalculationDropdown
                id={fieldId}
                sectionId={sectionId}
                onFormulaChange={onFormulaChange}
                onTagsChange={onTagsChange}
                formula={formula}
                tags={tags}
                sections={sections}
                calculationColumn={id}
                hideExplanation
              />
            </Col>
            )}
            {id === 'quantityAllocated' && (
            <Col flex="350px">
              <Checkbox checked={isReadOnly} onChange={onReadOnlyChange}> Read Only </Checkbox>
            </Col>
            )}
            <Col flex="30px" />
            <Col flex="30px">
              <BorderlessButton
                iconNode={<DeleteOutlined style={{ margin: 0, color: 'red' }} />}
                onClick={onDeleteClicked}
              />
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
}

export default TableFieldColumnCard;

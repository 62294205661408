import React from 'react';
import { Row, message } from 'antd';

import { CopyOutlined } from '@ant-design/icons';
import titleOnlyConfigure from './titleOnlyConfigure';
import TitleRow from './TitleRow';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import FieldTriggerFlag from './FieldTriggerFlag';

const preview = ({
  setPreviewProps,
  configProps = {},
  previewProps = {},
  id,
  responses = {},
  setResponses,
  responding = false,
  isDisplay,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  name,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
  } = configProps;
  const {
    value,
  } = previewProps;
  const {
    [id]: {
      value: response = '',
    } = {},
  } = responding ? responses : {};
  const onTextChange = (e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    const strippedValue = newValue ? newValue.replace(/\r/g, '') : newValue;
    if (responding) {
      const newResp = {
        [id]: {
          ...(responses[id]),
          value: strippedValue,
        },
      };
      setResponses({
        ...responses,
        ...newResp,
      });
    } else {
      if (!setPreviewProps) return;
      setPreviewProps({
        ...previewProps,
        value: strippedValue,
      });
    }
  };

  const onCopyClicked = async () => {
    await navigator.clipboard.writeText(value);
    message.success('Copied text');
  };

  const filterDisplay = (() => {
    if (isDisplay) {
      return (
        <BorderlessButton
          iconNode={<CopyOutlined />}
          style={{
            width: 'auto',
            height: 20,
            padding: 0,
          }}
          onClick={onCopyClicked}
        />
      );
    }
    if (fieldTrigger) {
      return (
        <FieldTriggerFlag
          sections={sections}
          isExternalForm={isExternalForm}
          templateId={templateId}
          projectId={projectId}
          divisions={divisions}
          configProps={configProps}
          responding={responding}
          id={id}
          fieldTriggerMap={fieldTriggerMap}
          setFieldTriggerMap={setFieldTriggerMap}
          name={name}
        />
      );
    }

    return null;
  });

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={filterDisplay()}
      />
      <Row style={{ marginTop: 15 }}>
        <OnTraccrTextInput
          readOnly={isDisplay}
          textarea
          value={responding ? response : value}
          onChange={onTextChange}
          autoSize
        />
      </Row>
    </div>
  );
};

export default {
  configure: titleOnlyConfigure,
  preview,
  title: 'Text input',
  description: 'User can enter text',
};

import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  customTableRow,
  getCustomFieldMap,
  getFormattedListViewTask,
  getSummaryViewColumns,
} from './timecardListSummaryView.helpers';
import { getIdMap } from '../helpers/helpers';
import Permissions from '../auth/Permissions';
import { getCustomFields, updateTimeCardFilters } from './state/timecards.actions';

export default function TimeCardsSummaryView({
  tasks = [],
  isApprovals,
  onSummaryTaskModify,
  onSummaryRowClick,
  onRowSelectChange,
  onTableChange,
  onFormClick,
  selectedSummaryRowKeys = [],
  entryDataMap,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasApproveAllPermissions = Permissions.has('ENABLE_APPROVE_ALL');

  const unions = useSelector((state) => state.unions.unions);
  const unionClasses = useSelector((state) => state.unions.classes);
  const unionLocals = useSelector((state) => state.unions.locals);
  const customFields = useSelector((state) => state.timecards.customFields);
  const users = useSelector((state) => state.users.users);
  const enableHourBasedTracking = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            enableHourBasedTracking: enableHourBasedTrackingSetting = false,
          } = {},
        } = {},
      } = {},
    } = state;
    return enableHourBasedTrackingSetting;
  });
  const {
    costcodes,
    phases,
  } = useSelector((state) => state.costcodes);

  const [selectedTimeCardSummaryColumns, setSelectedTimeCardSummaryColumns] = useState();
  const [selectedCustomFieldColumns, setSelectedCustomFieldColumns] = useState();

  const userMap = useMemo(() => getIdMap(users), [users]);
  const unionMap = useMemo(() => getIdMap(unions), [unions]);
  const unionClassMap = useMemo(() => getIdMap(unionClasses), [unionClasses]);
  const unionLocalMap = useMemo(() => getIdMap(unionLocals), [unionLocals]);
  const costCodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const phaseMap = useMemo(() => phases.reduce((acc, phase) => {
    if (!acc[phase.id]) acc[phase.id] = [];
    acc[phase.id].push(phase);
    return acc;
  }, {}), [phases]);

  const customFieldMap = useMemo(() => getCustomFieldMap(customFields), [customFields]);

  const filteredTasks = useMemo(() => {
    const seen = new Set();
    return tasks.filter((task) => !task.id || (!seen.has(task.id) && seen.add(task.id)));
  }, [tasks]);

  useEffect(() => {
    const onStorageUpdated = (e) => {
      if (!e || e.detail === 'timeCardSummaryColumns') {
        const rawTimeCardSummaryColumns = window.localStorage.getItem('timeCardSummaryColumns');
        if (rawTimeCardSummaryColumns) {
          setSelectedTimeCardSummaryColumns(JSON.parse(rawTimeCardSummaryColumns));
        }
      }

      if (!e || e.detail === 'timeCardCustomFieldColumns') {
        const rawCustomFieldColumns = window.localStorage.getItem('timeCardCustomFieldColumns');
        if (rawCustomFieldColumns) {
          setSelectedCustomFieldColumns(JSON.parse(rawCustomFieldColumns));
        }
      }
    };

    onStorageUpdated();
    window.addEventListener('localStorageSetItem', onStorageUpdated);

    return () => {
      window.removeEventListener('localStorageSetItem', onStorageUpdated);
    };
  }, []);

  const formattedTasks = useMemo(() => (
    filteredTasks
      .map((task) => getFormattedListViewTask({
        task,
        costCodeMap,
        phaseMap,
        enableHourBasedTracking,
        unionClassMap,
        unionLocalMap,
        unionMap,
        entryDataMap,
        userMap,
      }))
  ), [
    filteredTasks,
    costCodeMap,
    phaseMap,
    unionMap,
    unionClassMap,
    unionLocalMap,
    enableHourBasedTracking,
    userMap,
    entryDataMap,
  ]);

  const divisionIdsInTasks = useMemo(() => (
    new Set(formattedTasks.map((task) => task.divisionId))
  ), [formattedTasks]);

  const columns = useMemo(() => getSummaryViewColumns({
    t,
    isApprovals,
    onSummaryTaskModify,
    onFormClick,
    customFieldMap,
    divisionIds: divisionIdsInTasks,
    selectedTimeCardSummaryColumns,
    selectedCustomFieldColumns,
  }), [
    t,
    isApprovals,
    onSummaryTaskModify,
    onFormClick,
    customFieldMap,
    divisionIdsInTasks,
    selectedTimeCardSummaryColumns,
    selectedCustomFieldColumns,
  ]);

  const rowSelectionProps = useMemo(() => {
    if (!isApprovals) return {};

    return {
      rowSelection: {
        selectedRowKeys: selectedSummaryRowKeys,
        hideSelectAll: !hasApproveAllPermissions,
        onChange: onRowSelectChange,
      },
    };
  }, [selectedSummaryRowKeys, hasApproveAllPermissions, isApprovals, onRowSelectChange]);

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  useEffect(() => (
    () => {
      dispatch(updateTimeCardFilters(null));
    }
  ), []);

  return (
    <Table
      columns={columns}
      dataSource={formattedTasks}
      pagination={false}
      size="small"
      rowClassName={() => 'timecard-entry-row'}
      rowKey="id"
      bordered
      onRow={(rowRecord) => ({
        onClick: () => onSummaryRowClick(rowRecord.id),
        isPending: rowRecord.isPending,
        formUserName: rowRecord.formUserName,
        formCreatedAt: rowRecord.formCreatedAt,
        formName: rowRecord.formName,
        formNumber: rowRecord.formNumber,
      })}
      components={{
        body: {
          row: customTableRow,
        },
      }}
      onChange={onTableChange}
      {...rowSelectionProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardsSummaryView.propTypes = {
  tasks: PropTypes.array,
  isApprovals: PropTypes.bool.isRequired,
  onSummaryTaskModify: PropTypes.func.isRequired,
  onSummaryRowClick: PropTypes.func.isRequired,
  onRowSelectChange: PropTypes.func.isRequired,
  selectedSummaryRowKeys: PropTypes.array,
  entryDataMap: PropTypes.object.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onFormClick: PropTypes.func.isRequired,
};

TimeCardsSummaryView.defaultProps = {
  tasks: [],
  selectedSummaryRowKeys: [],
};

import React from 'react';
import moment from 'moment';
import {
  Table,
  Checkbox,
  notification,
  DatePicker,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import colors from '../constants/Colors';
import MaterialUnitsSelector from '../common/inputs/MaterialUnitsSelector';
import { currencyFormatter, currencyParser } from '../helpers/inputParsers';
import { sortByCode } from '../helpers/helpers';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import { getMaterialCostColumns, getEquipmentCostColumns } from '../projects/ProjectProgress/ProgressColumns';
import { isExpandableCostcode } from '../helpers/costcodeHelpers';

const { RangePicker } = DatePicker;

const NOTIFICATION_KEY = 'COST_CODE_UPLOAD_CONFLICT_NOTIFICATION';

const openNotification = (description) => {
  notification.open({
    key: NOTIFICATION_KEY,
    message: 'Cost Codes Error',
    description,
    duration: 0,
    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  });
};

const formatDates = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return '';
  return `${moment(startDate).format('YYYY-MM-DD')} -> ${moment(endDate).format('YYYY-MM-DD')}`;
};

const getExpandedRowRender = (record) => {
  const costcodeCostEstimates = record.costUpdates ?? [];
  const getColumns = record.category === 'Material' ? getMaterialCostColumns : getEquipmentCostColumns;

  return (
    <Table
      columns={getColumns()}
      dataSource={costcodeCostEstimates}
      pagination={false}
      size="small"
      style={{ marginBottom: 10 }}
      rowKey="id"
    />
  );
};

export default class CostCodeUploadList extends React.Component {
  constructor(props) {
    super(props);
    const { defaultSelected } = props;
    this.state = {
      selectedRowKeys: defaultSelected,
      sortedDataSource: [],
    };

    this.onPhasedChanged = this.phasedChanged.bind(this);

    this.codeMap = {};
    this.nameMap = {};
  }

  componentDidMount() {
    const {
      dataSource = [],
      shouldSort = true,
    } = this.props;
    this.checkDuplicates();

    let newData = [...dataSource];
    if (shouldSort) {
      newData.sort(sortByCode('code'));
    }

    this.setState((prevState) => ({
      ...prevState,
      sortedDataSource: newData,
    }));
  }

  componentDidUpdate(prevProps) {
    const {
      dataSource: prevData = [],
    } = prevProps;
    const {
      dataSource = [],
      shouldSort = true,
    } = this.props;
    if (prevData !== dataSource) {
      this.checkDuplicates();

      let newData = [...dataSource];
      if (shouldSort) {
        newData.sort(sortByCode('code'));
      }

      this.setState((prevState) => ({
        ...prevState,
        sortedDataSource: newData,
      }));
    }
  }

  getInputColumn({
    conflictMap = {},
    record = {},
    value,
    key,
  }) {
    const { onEdit } = this.props;
    const hasConflict = conflictMap[value] > 1;
    if (onEdit) {
      return (
        <OnTraccrTextInput
          value={value}
          onChange={(e) => {
            const newValue = e?.target?.value;
            onEdit(record.id, { [key]: newValue });
          }}
          style={
            !value || hasConflict
              ? {
                borderColor: 'red',
                textColor: 'red',
              }
              : {}
          }
        />
      );
    }
    return (
      <div
        style={{
          color: hasConflict ? 'red' : 'inherit',
          paddingLeft: 10,
        }}
      >
        {value}
      </div>
    );
  }

  checkDuplicates() {
    const {
      dataSource = [],
      conflictList,
      actionButtonEnabled
    } = this.props;

    this.codeMap = {};
    this.nameMap = {};

    let missingKey = false;
    
    const conflictCheck = conflictList ?? dataSource;

    dataSource?.forEach((data) => {
      if (!data.name || !data.code || !data.description) {
        missingKey = true;
      } else {
        this.nameMap[data.name] = (this.nameMap[data.name] || 0) + 1;
      }
    });
    // Code is unique across categories
    conflictCheck?.forEach((data) => {
      if (data.code) { 
        this.codeMap[data.code] = (this.codeMap[data.code] || 0) + 1;
      }
    });

    const nameClash = Object.keys(this.nameMap).filter((name) => name && this.nameMap[name] > 1);
    const codeClash = Object.keys(this.codeMap).filter((code) => code && this.codeMap[code] > 1);

    if (missingKey) {
      if (actionButtonEnabled) actionButtonEnabled(false);
    } else if (nameClash.length > 0 || codeClash.length > 0) {
      if (actionButtonEnabled) {
        const errorMessage = (
          <div>
            The following name/code conflicts were found:
            <br />
            <br />
            {nameClash.length
              ? (
                <div>
                  Name Conflicts:
                  <p style={{ color: 'red' }}>{nameClash.join(', ')}</p>
                  <br />
                </div>
              )
              : null}
            {codeClash.length
              ? (
                <div>
                  Code Conflicts:
                  <p style={{ color: 'red' }}>{codeClash.join(', ')}</p>
                  <br />
                </div>
              ) : null}
            Please edit the file to fix these conflics and try re-uploading.
          </div>
        );
        openNotification(errorMessage);
        actionButtonEnabled(false);
      }
    } else {
      notification.close(NOTIFICATION_KEY);
      if (actionButtonEnabled) actionButtonEnabled(true);
    }
  }

  phasedChanged(record, checked) {
    const { onPhaseChanged } = this.props;
    onPhaseChanged(record, checked);
  }
  
  getCategoryName(categories, categoryId) {
    const { name } = Object.values(categories).find((category) => category.id === categoryId) || {};
    return name;
  }

  render() {
    const {
      selectable = false,
      categories = false,
      phased = false,
      phaseClickable = true,
      onRemove,
      style = {},
      scroll = {},
      defaultSelected = [],
      onEstimateChanged,
      hours,
      cost,
      quantity,
      units,
      dates,
      onSelectedChanged,
      showCostEstimates = false,
    } = this.props;

    const {
      selectedRowKeys,
      sortedDataSource,
    } = this.state;

    const cols = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
          this.getInputColumn({
            conflictMap: this.nameMap,
            record,
            value: name,
            key: 'name',
          })
        ),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        render: (code, record) => (
          this.getInputColumn({
            conflictMap: this.codeMap,
            record,
            value: code,
            key: 'code',
          })
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (description, record) => (
          this.getInputColumn({
            record,
            value: description,
            key: 'description',
          })
        ),
      },
    ];

    const defaultCheckSet = new Set(defaultSelected);
    const tableProps = {};
    if (selectable) {
      tableProps.rowSelection = {
        onChange: (newSelectedKeys, selectedRows) => {
          this.setState({ selectedRowKeys: newSelectedKeys });
          onSelectedChanged(selectedRows);
        },
        getCheckboxProps: (record) => ({
          name: record.name,
          defaultChecked: defaultCheckSet.has(record.id),
        }),
        selectedRowKeys,
      };
    }

    if (showCostEstimates) {
      tableProps.expandable = {
        defaultExpandAllRows: false,
        expandedRowRender: (record) => {
          if (isExpandableCostcode(record.category)) {
            return getExpandedRowRender(record);
          }
          return null;
        },
        rowExpandable: (record) => (
          (isExpandableCostcode(record.category) && record?.costUpdates?.length)
        ),
      };
    }

    if (categories) {
      cols.unshift({
        title: 'Category',
        dataIndex: 'categoryId',
        key: 'categoryId',
        render: (_, record) => this.getCategoryName(categories, record.categoryId),
      });
    }

    if (phased) {
      cols.push({
        title: 'Phased',
        dataIndex: 'phased',
        key: 'phased',
        align: 'center',
        render: (_, record) => (
          <Checkbox
            onChange={(e) => this.onPhasedChanged(record, e.target.checked)}
            defaultChecked={record.phased}
            disabled={!phaseClickable}
          />
        ),
      });
    }

    if (hours) {
      cols.push({
        title: 'Hours',
        dataIndex: 'hours',
        key: 'hours',
        render: (_, record) => (
          onEstimateChanged
            ? (
              <OnTraccrNumberInput
                min={0}
                defaultValue={record.hours}
                onChange={(value) => {
                  const parsed = parseFloat(value);
                  onEstimateChanged(record, Number.isNaN(parsed) || !parsed
                    ? { hours: 0 }
                    : { hours: parsed });
                }}
                style={{ width: 100 }}
              />
            ) : record.hours
        ),
      });
    }

    if (cost) {
      cols.push({
        title: 'Cost',
        dataIndex: 'estimatedCost',
        key: 'estimatedCost',
        render: (_, record) => (
          onEstimateChanged
            ? (
              <OnTraccrNumberInput
                min={0}
                value={record.estimatedCost}
                onChange={(value) => {
                  const parsed = parseFloat(value);
                  onEstimateChanged(record, Number.isNaN(parsed) || !parsed
                    ? { estimatedCost: 0 }
                    : { estimatedCost: parsed });
                }}
                style={{ width: 100 }}
                formatter={currencyFormatter}
                parser={currencyParser}
              />
            ) : currencyFormatter(record.estimatedCost)
        ),
      });
    }

    if (quantity) {
      cols.push({
        title: 'Estimated Quantity',
        dataIndex: 'estimatedQuantity',
        key: 'estimatedQuantity',
        render: (_, record) => {
          if (!onEstimateChanged) return record.estimatedQuantity;
          if (!(record.estimatedQuantity || record.addQuantities)) {
            return {
              children: (
                <BorderlessButton
                  title="Add Quantities"
                  style={{ width: 150 }}
                  onClick={() => onEstimateChanged(record, { addQuantities: true })}
                />
              ),
              props: { colSpan: 1 },
            };
          }
          return (
            <OnTraccrNumberInput
              min={0}
              defaultValue={record.estimatedQuantity}
              onChange={(value) => {
                const parsed = parseFloat(value);
                onEstimateChanged(record, Number.isNaN(parsed) || !parsed
                  ? { estimatedQuantity: 0 }
                  : { estimatedQuantity: parsed });
              }}
              style={{ width: 100 }}
            />
          );
        },
      });
    }

    if (units) {
      cols.push({
        title: 'Units',
        dataIndex: 'units',
        key: 'units',
        render: (_, record) => {
          if (!(record.estimatedQuantity || record.addQuantities)) return null;
          if (onEstimateChanged) {
            return (
              <MaterialUnitsSelector
                value={record.units}
                onSelect={(newUnits) => onEstimateChanged(record, { units: newUnits })}
              />
            );
          }
          return record.units;
        },
      });
    }

    if (dates) {
      cols.push({
        title: 'Dates',
        dataIndex: 'dates',
        key: 'dates',
        render: (_, record) => (
          onEstimateChanged
            ? (
              <RangePicker
                style={{ width: 300 }}
                onChange={onEstimateChanged ? ([start, end] = []) => onEstimateChanged(record, {
                  startDate: start,
                  endDate: end,
                }) : null}
                defaultValue={(
                  record.startDate && record.endDate
                    ? [moment(record.startDate), moment(record.endDate)]
                    : null
                )}
              />
            )
            : formatDates(record)
        ),
      });
    }

    if (onRemove) {
      cols.push({
        title: 'Remove',
        dataIndex: 'remove',
        key: 'remove',
        align: 'center',
        render: (_, record) => (
          <BorderlessButton
            icon='delete'
            color={colors.ONTRACCR_RED}
            onClick={() => onRemove(record)}
            style={{ paddingRight: 8, paddingLeft: 0 }}
          />
        ),
      });
    }

    return (
      <Table
        size='small'
        columns={cols}
        dataSource={sortedDataSource.map((item) => ({
          ...item,
          key: item.id,
        }))}
        pagination={false}
        style={{ marginBottom: 50, ...style }}
        scroll={scroll}
        {...tableProps}
      />
    );
  }
}

CostCodeUploadList.propTypes = {
  onEdit: PropTypes.func,
};

CostCodeUploadList.defaultProps = {
  onEdit: null,
};

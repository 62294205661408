export const PRIORITY_OPTS = [
  { value: 'Lowest', label: 'Lowest' },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
  { value: 'Highest', label: 'Highest' },
];

export const STATUS_OPTS = [
  { value: 'To Do', label: 'To Do' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Done', label: 'Done' },
];

/*
  In the future we might add smaller granularity to due date and reminders
  { value: 'minutes', label: 'Minutes' },
  { value: 'hours', label: 'Hours' },
*/
export const TIME_OPTS = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
];

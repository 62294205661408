import { DateTime } from 'luxon';
import { isObjectEmpty } from 'ontraccr-common/lib/Common';
import { FormHelpers } from 'ontraccr-common';

import {
  CREATE_FORM_TEMPLATE,
  UPDATE_FORM_TEMPLATE,
  GET_FORM_TEMPLATES,
  ARCHIVE_FORM_TEMPLATE,
  GET_FORM_TEMPLATE_DETAILS,
  DELETE_FORM_TEMPLATE,
  GET_FORMS,
  GET_FORM_BY_ID,
  GET_FORM_APPROVALS,
  APPROVE_FORM,
  REJECT_FORM,
  FORM_SOCKET_UPDATE,
  FORM_APPROVAL_SOCKET_UPDATE,
  FORM_NUMBER_SOCKET_UPDATE,
  GET_ASSIGNED_FORMS,
  GET_ASSIGNED_FORM_TEMPLATES,
  GET_FORM_STATUSES,
  GET_FORM_TYPES,
  SUBMIT_FORM,
  CREATE_CUSTOM_FORM_TABLE,
  GET_CUSTOM_FORM_TABLES,
  UPDATE_CUSTOM_FORM_TABLE,
  DELETE_CUSTOM_FORM_TABLE,
  SAVE_FORM_DRAFT,
  GET_FORM_DRAFTS,
  DELETE_FORM_DRAFT,
  MARK_INVOICE_AS_PAID,
  RESUBMIT_EMAIL,
  DELETE_FORM,
  CANCEL_FORM,
  FORM_APPROVAL_DELETE,
  CLEAR_SELECTED_FORM,
  GET_SHIFT_EVENT_FORM_DATA,
  COPY_FORM_TEMPLATE,
  FAVORITE_FORM_TEMPLATE,
  GET_FORM_CHILDREN,
  REASSIGN_FORM,
  REASSIGN_DRAFT,
  CREATE_CUSTOM_TYPE,
  DELETE_CUSTOM_TYPE,
  UPDATE_CUSTOM_TYPE,
  GET_EXTERNAL_FORM_TEMPLATES,
  GET_EXTERNAL_FORM_TEMPLATE_BY_ID,
  GET_USER_FORM_FILTER_VIEWS,
  CREATE_USER_FORM_FILTER_VIEW,
  UPDATE_USER_FORM_FILTER_VIEW,
  DELETE_USER_FORM_FILTER_VIEW,
  UPDATE_FORM_FILTERS,
  GET_LIGHTWEIGHT_FORMS,
  GET_CHILD_FORMS,
  GET_FORM_SNAPSHOTS,
  UPDATE_SELECT_FORM_FILES,
  GET_SUB_CONTRACT_DETAILS,
} from '../../state/actionTypes';

import { getIdMap } from '../../helpers/helpers';

const initialState = {
  templates: {},
  templatesIsLoading: true,
  templatePreviews: {},
  forms: {},
  selectedForm: {},
  approvals: {},
  types: [],
  assignedForms: [],
  assignedFormTemplates: [],
  selectedFormTemplate: {},
  statuses: {},
  customTables: {},
  drafts: {
    manual: {},
    assigned: {},
  },
  latestManualDraftId: null,
  triggeredForms: [],
  externalFormTemplates: {},
  templateForms: [],
  formFilters: null,
  formFilterViews: [],
  lightWeightForms: [],
  lightWeightSubContracts: [],
  childForms: [],
  formSnapshotMap: {},
  circularFieldIds: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FORMS: {
      const {
        payload: {
          forms = [],
        } = {},
      } = action;

      return {
        ...state,
        forms: getIdMap(forms),
      };
    }
    case GET_LIGHTWEIGHT_FORMS: {
      const {
        payload: {
          forms = [],
          templateId,
          subContractTemplateId,
        } = {},
      } = action;

      const { completedForms, subContractForms } = forms.reduce((acc, form) => {
        if (templateId && templateId === form.templateId) {
          acc.completedForms.push(form);
        } else if (subContractTemplateId && subContractTemplateId === form.templateId) {
          acc.subContractForms.push(form);
        }
        return acc;
      }, { completedForms: [], subContractForms: [] });

      return {
        ...state,
        lightWeightForms: completedForms,
        lightWeightSubContracts: subContractForms,
      };
    }
    case GET_FORM_BY_ID: {
      const {
        payload: {
          form,
        } = {},
      } = action;
      const circularFieldIds = form?.data?.circularFieldIds || [];
      return {
        ...state,
        selectedForm: form,
        circularFieldIds,
      };
    }
    case UPDATE_SELECT_FORM_FILES: {
      if (!state.selectedForm) {
        return state;
      }
      const {
        payload: {
          fileId = '',
          rotation = 0,
        } = {},
      } = action;
      const {
        attachments: {
          instantiatedFiles = [],
        } = {},
        fileMap = {},
      } = state.selectedForm;
      const newInstantiatedFiles = instantiatedFiles.map((file) => {
        if (file?.id === fileId) {
          return { ...file, rotation };
        }
        return file;
      });

      const newFileMap = { ...fileMap, [fileId]: { ...fileMap[fileId], rotation } };
      return {
        ...state,
        selectedForm: {
          ...state.selectedForm,
          fileMap: newFileMap,
          attachments: {
            ...state.selectedForm.attachments,
            instantiatedFiles: newInstantiatedFiles,
          },
        },
      };
    }
    case CLEAR_SELECTED_FORM: {
      return {
        ...state,
        selectedForm: {},
        selectedFormTemplate: {},
        triggeredForms: [],
        latestManualDraftId: null,
      };
    }

    case RESUBMIT_EMAIL: {
      const {
        payload: {
          steps,
        } = {},
      } = action;
      return {
        ...state,
        selectedForm: {
          ...state.selectedForm,
          steps,
        },
      };
    }
    case GET_FORM_STATUSES: {
      const {
        payload: {
          statuses = [],
        } = {},
      } = action;
      return {
        ...state,
        statuses: getIdMap(statuses),
      };
    }
    case GET_FORM_APPROVALS: {
      const {
        payload: {
          approvals = [],
        } = {},
      } = action;
      const approvalMap = {};
      approvals.forEach((approval) => {
        approvalMap[approval.formId] = approval;
      });
      return {
        ...state,
        approvals: approvalMap,
      };
    }
    case APPROVE_FORM:
    case REJECT_FORM: {
      const {
        payload: {
          form = {},
          approvalId,
        } = {},
      } = action;
      const {
        approvals = {},
        forms: stateForms = {},
      } = state;
      const newApprovals = { ...approvals };
      if (newApprovals[form.id]?.id === approvalId) {
        /*
          There is a race condition here when multi-stage approvals
          have the same user. The second approval is created via
          FORM_APPROVAL_SOCKET_UPDATE and then the API call returns
          executing this case. We need to check approval we sent
          is the same as the one in this state before deleting
        */
        delete newApprovals[form.id];
      }
      const newForms = { ...stateForms };
      const {
        [form.id]: stateForm = {},
      } = newForms;
      const ourForm = { ...stateForm };
      ourForm.state = form.state;
      newForms[form.id] = ourForm;
      return {
        ...state,
        approvals: newApprovals,
        selectedForm: form,
        forms: newForms,
      };
    }
    case GET_FORM_TEMPLATES: {
      const {
        payload: {
          templates = [],
          types = [],
          previews,
        } = {},
      } = action;
      const newState = {
        ...state,
        templates: getIdMap(templates),
        templatesIsLoading: false,
        types,
      };
      if (previews) {
        newState.templatePreviews = getIdMap(previews);
      }
      return newState;
    }
    case COPY_FORM_TEMPLATE:
    case CREATE_FORM_TEMPLATE:
    case UPDATE_FORM_TEMPLATE: {
      const {
        payload: {
          formTemplate = {},
          newStatuses = [],
          preview,
        } = {},
      } = action;
      const isCreate = action.type === CREATE_FORM_TEMPLATE
        || action.type === COPY_FORM_TEMPLATE;
      const {
        id,
        divisionId,
        typeId,
        sections,
        collected,
        workflow,
        name,
        allowEdit,
        allowResubmission,
        allowReassignment,
        allowSubmitChild,
        projectId,
        valueField,
        isExternalForm,
        useStandardTemplate,
        settings = {},
        displayFields = [],
        showInfoBar,
      } = formTemplate;
      if (!id) return state;
      const {
        templates = {},
        types = [],
        statuses = {},
        templatePreviews = {},
      } = state;
      const { name: typeName } = types.find((type) => type.id === typeId) || {};
      const newTemplates = { ...templates };
      const {
        [id]: oldTemplate = {},
      } = newTemplates;
      const {
        formData: oldFormData = {},
        projectId: oldProjectId,
      } = oldTemplate;
      newTemplates[id] = {
        ...oldTemplate,
        name,
        id,
        typeId,
        divisionId,
        formData: {
          ...oldFormData,
          sections,
          collected,
          workflow,
        },
        type: typeName,
        active: isCreate ? 1 : oldTemplate.active,
        notDeleted: isCreate ? 1 : oldTemplate.notDeleted,
        allowEdit,
        allowResubmission,
        allowReassignment,
        allowSubmitChild,
        projectId: projectId || oldProjectId,
        valueField,
        isExternalForm,
        settings,
        useStandardTemplate,
        displayFields,
        jsonFormData: JSON.stringify({
          ...oldFormData,
          sections,
          collected,
          workflow,
        }),
        showInfoBar,
      };

      const newStatusObject = { ...statuses };
      newStatuses.forEach((status) => {
        newStatusObject[status.id] = status;
      });

      const newPreviews = { ...templatePreviews ?? {} };
      newPreviews[id] = { id, file: preview };
      return {
        ...state,
        templates: newTemplates,
        statuses: newStatusObject,
        templatePreviews: newPreviews,
      };
    }
    case FAVORITE_FORM_TEMPLATE: {
      const {
        payload: {
          id,
          favorite,
        } = {},
      } = action;
      const {
        templates: stateTemplates = {},
        assignedFormTemplates: stateAssignedFormTemplates = [],
      } = state;
      const stateAssignedFormTemplateMap = getIdMap(stateAssignedFormTemplates);
      let newTemplates = { ...stateTemplates };
      let newAssignedFormTemplates = { ...stateAssignedFormTemplateMap };
      const stateTemplate = newTemplates[id];
      const stateAssignedTemplate = newAssignedFormTemplates[id];
      if (stateTemplate) {
        newTemplates = {
          ...newTemplates,
          [id]: { ...stateTemplate, isFavorite: favorite },
        };
      }
      if (stateAssignedTemplate) {
        newAssignedFormTemplates = {
          ...newAssignedFormTemplates,
          [id]: { ...stateAssignedTemplate, isFavorite: favorite },
        };
      }
      return {
        ...state,
        templates: newTemplates,
        assignedFormTemplates: Object.values(newAssignedFormTemplates),
      };
    }
    case ARCHIVE_FORM_TEMPLATE: {
      const {
        payload: {
          id,
          active,
        } = {},
      } = action;
      const {
        templates = {},
      } = state;
      const newTemplates = { ...templates };
      if (!(id in newTemplates)) return state;
      newTemplates[id] = {
        ...newTemplates[id],
        active: active ? 1 : 0,
      };
      return {
        ...state,
        templates: newTemplates,
      };
    }
    case GET_FORM_TEMPLATE_DETAILS: {
      const {
        payload: {
          formTemplate = {},
          fieldMappings = {},
          circularFieldIds: existingCircularFieldIds = [],
        } = {},
      } = action;

      let circularFieldIds = existingCircularFieldIds;

      // If mapping from a board card to a form, we want to prevent table edits
      // to ensure the integrity of the original data
      if (!isObjectEmpty(fieldMappings)) {
        circularFieldIds = FormHelpers.getCircularWorkflowFields({
          workflows: formTemplate?.formData?.workflow,
          ids: Object.values(fieldMappings), // card fields
        });
      }

      if (circularFieldIds.length) {
        const newSections = FormHelpers.injectConfigIntoSections({
          sections: formTemplate?.formData?.sections,
          fieldTypes: ['table'],
          dataTypes: ['Materials', 'Equipment'],
          ids: circularFieldIds,
          config: { preventEdits: true },
        });
        formTemplate.formData.sections = newSections;
      }

      const { id } = formTemplate;
      const {
        templates = {},
        types = {},
      } = state;
      const newTemplates = { ...templates };
      if (!(id in newTemplates)) {
        return {
          ...state,
          selectedFormTemplate: formTemplate,
        };
      }
      const {
        typeId,
      } = formTemplate;
      const { name: typeName } = types.find((type) => type.id === typeId) || {};

      newTemplates[id] = {
        ...newTemplates[id],
        ...formTemplate,
        type: typeName,
      };

      return {
        ...state,
        templates: newTemplates,
        selectedFormTemplate: newTemplates[id],
        circularFieldIds,
      };
    }
    case GET_EXTERNAL_FORM_TEMPLATE_BY_ID:
    case GET_SHIFT_EVENT_FORM_DATA: {
      const {
        payload: {
          formTemplate = {},
        } = {},
      } = action;
      return {
        ...state,
        selectedFormTemplate: formTemplate,
      };
    }
    case DELETE_FORM_TEMPLATE: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const {
        templates = {},
      } = state;

      const newTemplates = { ...templates };
      if (!(id in newTemplates)) return state;
      delete newTemplates[id];
      return {
        ...state,
        templates: newTemplates,
      };
    }
    case FORM_SOCKET_UPDATE: {
      const {
        payload: {
          form,
        } = {},
      } = action;
      const {
        forms: stateForms = {},
      } = state;
      const newForms = { ...stateForms };
      newForms[form.id] = form;
      return {
        ...state,
        forms: newForms,
      };
    }
    case FORM_APPROVAL_SOCKET_UPDATE: {
      const {
        payload: {
          approval = {},
        } = {},
      } = action;
      const {
        approvals: stateApprovals = {},
      } = state;
      const newApprovals = { ...stateApprovals };
      newApprovals[approval.formId] = approval;
      return {
        ...state,
        approvals: newApprovals,
      };
    }
    case FORM_NUMBER_SOCKET_UPDATE: {
      const {
        payload: {
          formNumberMap = {},
        } = {},
      } = action;
      const {
        forms: stateForms = {},
      } = state;
      const newForms = { ...stateForms };
      Object.entries(formNumberMap).forEach(([formId, number]) => {
        const form = newForms[formId];
        const newForm = { ...form, number };
        newForms[formId] = newForm;
      });
      return {
        ...state,
        forms: newForms,
      };
    }
    case GET_ASSIGNED_FORMS: {
      const {
        payload: {
          forms: payloadForms = [],
        } = {},
      } = action;
      return {
        ...state,
        assignedForms: payloadForms,
      };
    }
    case GET_ASSIGNED_FORM_TEMPLATES: {
      const {
        payload: {
          formTemplates = [],
        } = {},
      } = action;
      return {
        ...state,
        assignedFormTemplates: formTemplates,
      };
    }
    case GET_FORM_TYPES: {
      const {
        payload: {
          types = [],
        } = {},
      } = action;
      return {
        ...state,
        types,
      };
    }
    case SUBMIT_FORM: {
      const {
        payload: {
          submittedForm,
          isAssignedForm,
        } = {},
      } = action;
      const {
        assignedForms: stateAssignedForms = [],
        drafts: stateDrafts = {},
      } = state;
      const newDrafts = { ...stateDrafts };
      if (isAssignedForm) {
        delete newDrafts.assigned[submittedForm.id];
        newDrafts.assigned = {
          ...newDrafts.assigned,
        };
      } else {
        delete newDrafts.manual[submittedForm.draftId];
        newDrafts.manual = {
          ...newDrafts.manual,
        };
      }
      return {
        ...state,
        assignedForms: stateAssignedForms.filter((form) => form.id !== submittedForm.id),
        drafts: newDrafts,
      };
    }
    case GET_CUSTOM_FORM_TABLES: {
      const {
        payload: {
          customTables = [],
        } = {},
      } = action;
      return {
        ...state,
        customTables: getIdMap(customTables),
      };
    }
    case CREATE_CUSTOM_FORM_TABLE: {
      const {
        payload: {
          customTable = {},
        } = {},
      } = action;
      const { customTables = {} } = state;
      const newCustomTables = { ...customTables };
      newCustomTables[customTable.id] = customTable;
      return {
        ...state,
        customTables: newCustomTables,
      };
    }
    case UPDATE_CUSTOM_FORM_TABLE: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const { customTables = {} } = state;
      const newCustomTables = { ...customTables };
      const {
        [id]: oldData = {},
      } = newCustomTables;
      newCustomTables[id] = {
        ...oldData,
        ...newData,
      };
      return {
        ...state,
        customTables: newCustomTables,
      };
    }
    case DELETE_CUSTOM_FORM_TABLE: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { customTables = {} } = state;
      const newCustomTables = { ...customTables };
      delete newCustomTables[id];
      return {
        ...state,
        customTables: newCustomTables,
      };
    }
    case SAVE_FORM_DRAFT: {
      const {
        payload: {
          submittedForm,
        } = {},
      } = action;
      const { drafts: stateDrafts = {} } = state;
      const newDrafts = { ...stateDrafts };
      let manualDraftId;
      if (submittedForm.formId) {
        newDrafts.assigned = {
          ...newDrafts.assigned,
          [submittedForm.formId]: submittedForm,
        };
      } else {
        newDrafts.manual = {
          ...newDrafts.manual,
          [submittedForm.id]: submittedForm,
        };
        manualDraftId = submittedForm.id;
      }

      return {
        ...state,
        drafts: newDrafts,
        latestManualDraftId: manualDraftId ?? null,
      };
    }
    case GET_FORM_DRAFTS: {
      const {
        payload: {
          drafts = [],
        } = {},
      } = action;
      const newDrafts = {
        manual: {},
        assigned: {},
      };
      drafts.forEach((draft) => {
        const { data: rawData, formId, id: draftId } = draft;
        let data = {};
        try {
          data = JSON.parse(rawData);
        } catch (err) { /* */ }
        const fullDraft = { ...draft, data };
        if (formId) {
          newDrafts.assigned = {
            ...newDrafts.assigned,
            [formId]: fullDraft,
          };
        } else {
          newDrafts.manual = {
            ...newDrafts.manual,
            [draftId]: fullDraft,
          };
        }
      });
      return {
        ...state,
        drafts: newDrafts,
      };
    }
    case DELETE_FORM_DRAFT: {
      const {
        payload: {
          formId, id,
        } = {},
      } = action;
      const { drafts: stateDrafts = {} } = state;
      const newDrafts = { ...stateDrafts };
      if (formId) {
        delete newDrafts.assigned[formId];
        newDrafts.assigned = {
          ...newDrafts.assigned,
        };
      } else {
        delete newDrafts.manual[id];
        newDrafts.manual = {
          ...newDrafts.manual,
        };
      }
      return {
        ...state,
        drafts: newDrafts,
      };
    }
    case MARK_INVOICE_AS_PAID: {
      const {
        payload: {
          id: formId,
        } = {},
      } = action;
      const { forms: stateForms = {} } = state;
      const newForms = { ...stateForms };
      const {
        [formId]: oldForm = {},
      } = stateForms;
      newForms[formId] = {
        ...oldForm,
        hasQuickBooksInvoice: 0,
      };
      return {
        ...state,
        forms: newForms,
      };
    }
    case DELETE_FORM: {
      const {
        payload: {
          formIds = [],
        } = {},
      } = action;
      const {
        assignedForms = [],
        drafts = {},
        forms = {},
      } = state;

      const { assigned: assignedDrafts = {} } = drafts;
      const deleteIdSet = new Set(formIds);

      const newAssignedDrafts = { ...assignedDrafts };
      const newForms = { ...forms };

      formIds.forEach((formId) => {
        delete newAssignedDrafts[formId];
        delete newForms[formId];
      });

      Object.keys(newForms).forEach((formId) => {
        const {
          [formId]: form = {},
        } = newForms;
        if (deleteIdSet.has(form.parentId)) {
          newForms[formId] = {
            ...form,
            parentId: null,
          };
        }
      });

      return {
        ...state,
        assignedForms: assignedForms.filter((form) => !deleteIdSet.has(form.id)),
        forms: newForms,
        drafts: {
          ...drafts,
          assigned: newAssignedDrafts,
        },
      };
    }
    case CANCEL_FORM: {
      const {
        payload: {
          id: formId,
          isPOClose,
          formValue,
        } = {},
      } = action;
      const {
        forms: stateForms = {},
        approvals: stateApprovals = {},
        statuses = {},
        assignedForms = [],
      } = state;

      const ourStatus = Object.values(statuses).find((formStatus) => formStatus.status === 'Cancelled');
      if (!ourStatus && !isPOClose) return state;

      // Update approvals:
      const newApprovals = { ...stateApprovals };
      const {
        [formId]: oldApproval = {},
      } = newApprovals;
      if (!isPOClose) {
        newApprovals[formId] = {
          ...oldApproval,
          status: 'Cancelled',
          statusId: ourStatus.id,
        };
      }

      // Update forms:
      const newForms = { ...stateForms };
      const {
        [formId]: oldForm = {},
      } = newForms;
      const newForm = { ...oldForm };
      if (!isPOClose) {
        newForm.status = 'Cancelled';
        newForm.statusId = ourStatus.id;
      } else {
        newForm.isClosed = true;
        newForm.formValue = formValue;
      }

      newForms[formId] = newForm;

      const newAssigned = assignedForms.filter((form) => form.id !== formId);

      if ((formId in stateApprovals) && (formId in stateForms)) {
        return {
          ...state, forms: newForms, approvals: newApprovals, assignedForms: newAssigned,
        };
      }
      if (!(formId in stateApprovals) && (formId in stateForms)) {
        return { ...state, forms: newForms, assignedForms: newAssigned };
      }
      if ((formId in stateApprovals) && !(formId in stateForms)) {
        return { ...state, approvals: newApprovals, assignedForms: newAssigned };
      }
      return state;
    }
    case FORM_APPROVAL_DELETE: {
      const {
        payload: {
          approvalIds = [],
        } = {},
      } = action;
      const {
        approvals: stateApprovals = {},
      } = state;
      if (!approvalIds?.length) return state;

      const deleteSet = new Set(approvalIds);
      // Approvals looks like { [formId]: { id: approvalId }}
      const newApprovals = {};
      Object.keys(stateApprovals).forEach((formId) => {
        const approval = stateApprovals[formId];
        if (!deleteSet.has(approval.id)) {
          newApprovals[formId] = approval;
        }
      });
      return {
        ...state,
        approvals: newApprovals,
      };
    }
    case GET_FORM_CHILDREN: {
      const {
        payload: {
          children = [],
        } = {},
      } = action;

      return {
        ...state,
        triggeredForms: children,
      };
    }
    case REASSIGN_FORM: {
      const {
        payload: {
          removedUsers = [],
          userId,
          updatedForm,
        } = {},
      } = action;
      const newState = {
        ...state,
      };

      const {
        assignedForms: stateAssignedForms = [],
        forms: stateForms = {},
        drafts: stateDrafts = {},
        selectedForm: stateSelectedForm = {},
      } = state;
      const newDrafts = { ...stateDrafts };
      const newForms = { ...stateForms };
      const newSelectedForm = { ...stateSelectedForm };

      if (removedUsers.includes(userId)) {
        // If we've been unassigned, clear and remove drafts
        newState.selectedForm = {};
        newState.selectedFormTemplate = {};
        newState.triggeredForms = [];
        delete newDrafts.assigned[updatedForm.id];
        newDrafts.assigned = {
          ...newDrafts.assigned,
        };
        newState.drafts = newDrafts;
        newState.assignedForms = stateAssignedForms.filter((form) => form.id !== updatedForm.id);
      } else {
        newSelectedForm.users = updatedForm.users;
        newState.selectedForm = newSelectedForm;
        newState.assignedForms = stateAssignedForms.map((form) => (form.id === updatedForm.id
          ? {
            ...form,
            users: updatedForm.users,
          }
          : form));
      }

      newForms[updatedForm.id] = updatedForm;
      newState.forms = newForms;

      return newState;
    }
    case REASSIGN_DRAFT: {
      const {
        payload: {
          id: draftId,
          users = [],
          userId,
          newForm,
        } = {},
      } = action;
      const newState = {
        ...state,
      };

      const {
        assignedForms: stateAssignedForms = [],
        forms: stateForms = {},
        drafts: stateDrafts = {},
        selectedForm: stateSelectedForm = {},
      } = state;
      const newDrafts = { ...stateDrafts };
      const newForms = { ...stateForms };
      const newSelectedForm = { ...stateSelectedForm };

      if (!users.includes(userId)) {
        // If we've been unassigned, clear and remove draft
        newState.selectedForm = {};
        delete newDrafts.manual[draftId];
        newDrafts.manual = {
          ...newDrafts.manual,
        };
        newState.drafts = newDrafts;
      } else {
        // Update Draft and add new assigned form
        const oldDraft = newDrafts.manual[draftId];
        newDrafts.assigned[newForm.id] = {
          ...oldDraft,
          formId: newForm.id,
          lastUpdated: DateTime.local().toMillis(),
        };
        delete newDrafts.manual[draftId];
        newState.drafts = newDrafts;

        const newAssigned = [...stateAssignedForms];
        newAssigned.unshift(newForm);
        newState.assignedForms = newAssigned;

        newSelectedForm.users = newForm.users;
        newState.selectedForm = newSelectedForm;
      }
      newForms[newForm.id] = newForm;
      newState.forms = newForms;

      return newState;
    }
    case CREATE_CUSTOM_TYPE: {
      const {
        payload: {
          name: {
            companyId,
            name,
          },
        } = {},
      } = action;
      const { types: stateTypes } = state;
      return { ...state, types: stateTypes.concat([{ companyId, name }]) };
    }
    case DELETE_CUSTOM_TYPE: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { types } = state;
      const newTypes = types.filter((type) => type.id !== id);
      return {
        ...state,
        types: newTypes,
      };
    }
    case UPDATE_CUSTOM_TYPE: {
      const {
        payload: {
          id,
          name,
        } = {},
      } = action;
      const { types } = state;
      const newTypes = types.map((customType) => {
        if (customType.id !== id) return customType;
        return { ...customType, name };
      });
      return {
        ...state,
        types: newTypes,
      };
    }
    case GET_EXTERNAL_FORM_TEMPLATES: {
      const {
        payload: {
          externalFormTemplates = {},
        } = {},
      } = action;
      return {
        ...state,
        externalFormTemplates,
      };
    }
    case UPDATE_FORM_FILTERS: {
      const {
        payload: {
          formFilters,
        } = {},
      } = action;
      const newFormFilters = formFilters
        ? { ...state.formFilters, ...formFilters }
        : {};
      return {
        ...state,
        formFilters: newFormFilters,
      };
    }
    case GET_USER_FORM_FILTER_VIEWS:
    case CREATE_USER_FORM_FILTER_VIEW:
    case UPDATE_USER_FORM_FILTER_VIEW:
    case DELETE_USER_FORM_FILTER_VIEW:
      return {
        ...state,
        formFilterViews: action.payload.formFilterViews,
      };
    case GET_CHILD_FORMS: {
      const {
        payload: {
          childForms,
        } = {},
      } = action;
      return {
        ...state,
        childForms,
      };
    }
    case GET_FORM_SNAPSHOTS: {
      const {
        payload: {
          formId,
          snapshots,
        } = {},
      } = action;
      return {
        ...state,
        formSnapshotMap: {
          ...state.formSnapshotMap,
          [formId]: snapshots,
        },
      };
    }
    case GET_SUB_CONTRACT_DETAILS: {
      const {
        payload: {
          details,
        } = {},
      } = action;

      const {
        subContractMap: stateSubContractMap = {},
      } = state;

      const subContractMap = {
        ...stateSubContractMap,
        ...details,
      };

      return {
        ...state,
        subContractMap,
      };
    }
    default:
      return state;
  }
};

export default {};

export const DEFAULT_TITLE = 'Ontraccr';

export const ROUTE_TO_TEXT = (t) => ({
  dashboard: 'Dashboard',
  schedule: 'Field Schedule',
  'gantt-schedule': 'Gantt Schedule',
  'timecards/my': 'My Cards',
  'timecards/team': 'Team Cards',
  users: 'Users',
  teams: 'Teams',
  'contacts/customers': t('Customer', { count: 2 }),
  'contacts/vendors': 'Vendors',
  'contacts/addressbook': 'Address Book',
  projects: t('Project', { count: 2 }),
  projectGroups: t('ProjectGroup', { count: 2 }),
  payables: 'Payables',
  'costcodes/global': 'Global Codes',
  'costcodes/project-specific': `${t('Project')} Specific`,
  equipment: 'Equipment',
  materials: 'Materials',
  boards: 'Boards',
  files: 'Files',
  forms: 'Forms',
  reports: 'Reports',
  exports: 'Exports',
  analytics: 'Analytics',
  settings: 'Settings',
});

export const getPathTitle = (t, pathname) => {
  const pathParts = pathname?.substring(1)?.split('/');
  let fullPath = '';
  const routeMap = ROUTE_TO_TEXT(t);
  for (let i = 0; i < pathParts.length; i += 1) {
    fullPath += `${i > 0 ? '/' : ''}${pathParts[i]}`;
    if (routeMap[fullPath]) return routeMap[fullPath];
  }
  return DEFAULT_TITLE;
};

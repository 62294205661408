/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { Popover, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { isNullOrUndefined } from '../../helpers/helpers';
// eslint-disable-next-line import/no-cycle
import GanttScheduleTableInput from './GanttScheduleTableInput';

export const calculateDateDifferenceInDays = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0;
  }

  const start = DateTime.fromSeconds(startDate);
  const end = DateTime.fromSeconds(endDate);
  const difference = end.diff(start, 'days').toObject();

  return Math.round(difference.days);
};

export const defaultWorkingDays = [1, 2, 3, 4, 5, 6, 7];

export const workingDayMap = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const getDateDifference = (difference) => (
  `${difference > 0 ? '+' : ''}${difference} ${Math.abs(difference) > 1 || !difference ? 'days' : 'day'}`
);

const dateDifferenceFormatter = (difference) => (
  <span style={{ color: difference > 0 ? 'red' : 'green' }}>
    {getDateDifference(difference)}
  </span>
);

const dateFormatter = (date) => {
  if (!date) {
    return '';
  }

  const dateObject = DateTime.fromMillis(date.valueOf()).toUTC();
  return `${dateObject.year}/${dateObject.month}/${dateObject.day}`;
};

export const filterViewFields = [
  {
    field: 'name',
    defaultValue: '',
  },
  {
    field: 'dateRange',
    defaultValue: null,
  },
  {
    field: 'users',
    defaultValue: [],
  },
  {
    field: 'materials',
    defaultValue: [],
  },
  {
    field: 'equipment',
    defaultValue: [],
  },
  {
    field: 'phases',
    defaultValue: [],
  },
  {
    field: 'costCodes',
    defaultValue: [],
  },
  {
    field: 'labels',
    defaultValue: [],
  },
  {
    field: 'schedules',
    defaultValue: [],
  },
];

// Assumes no duplicates
const areArraysEqual = (a, b) => {
  if (a.length !== b.length) {
    return false;
  }

  let areEqual = true;

  const aMap = new Set(a);
  b.forEach((item) => {
    if (!aMap.has(item)) {
      areEqual = false;
      return;
    }

    aMap.delete(item);
  });

  return areEqual && aMap.size === 0;
};

export const areDatesEqual = (a, b) => {
  // If only one is null, they are not equal
  if (!a) {
    return a === b;
  }

  if (isNullOrUndefined(b) || isNullOrUndefined(a)) {
    return false;
  }

  return a.valueOf() === b.valueOf();
};

const createListDisplay = (
  title,
  items,
  formatter = (item) => item.name,
) => (
  <div>
    <h4>{title}</h4>
    { items.length
      ? (
        <ul style={{ paddingLeft: 18 }}>
          {items.map((item) => (
            <li key={item.id}>
              {formatter(item)}
            </li>
          ))}
        </ul>
      ) : 'N/A'}
  </div>
);

const createColumnPopover = (
  popoverTitle,
  items,
  children,
  formatter = (item) => item.name,
) => (
  items.length ? (
    <Popover
      placement="left"
      content={createListDisplay(popoverTitle, items, formatter)}
    >
      {children}
    </Popover>
  ) : children
);

/**
 * function to validate if string is not empty
 * @param {string} value string to validate
 * @returns {boolean} true if string is not empty
 */
const validateRequiredString = (value) => value && value.length;

const validateNumberInput = ({
  min = Number.MIN_VALUE,
  max = Number.MAX_VALUE,
}) => (value) => !isNullOrUndefined(value) && value >= min && value <= max;

export const getVisibleColumnMap = ({
  tasks,
  activeTab,
}) => ({
  Name: {
    label: 'Name',
    property: 'name',
    width: 250,
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Name"
        value={value}
        task={record}
        property="name"
        rules={[{ validator: validateRequiredString, message: 'Name is required' }]}
        width={230}
        activeTab={activeTab}
      />
    ),
  },
  'Start Date': {
    label: 'Start',
    property: 'realStartDate',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Start Date"
        type="date"
        value={moment.utc(value * 1000)}
        task={record}
        property="startDate"
        displayFormatter={dateFormatter}
        format="YYYY/M/D"
        allowClear={false}
        areEqual={areDatesEqual}
        width={90}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: dateFormatter(moment.utc(val * 1000)),
      style: 'tableCell',
    }),
    csvFormat: (val) => dateFormatter(moment.utc(val * 1000)),
    width: 110,
  },
  'End Date': {
    label: 'End',
    property: 'realEndDate',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update End Date"
        type="date"
        value={moment.utc(value * 1000)}
        task={record}
        property="endDate"
        displayFormatter={dateFormatter}
        format="YYYY/M/D"
        allowClear={false}
        areEqual={areDatesEqual}
        width={90}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: dateFormatter(moment.utc(val * 1000)),
      style: 'tableCell',
    }),
    csvFormat: (val) => dateFormatter(moment.utc(val * 1000)),
    width: 110,
  },
  Duration: {
    label: 'Duration',
    property: 'duration',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Duration"
        type="number"
        value={value}
        task={record}
        min={1}
        property="duration"
        defaultValue={1}
        displayFormatter={(val) => `${val} ${val > 1 ? 'days' : 'day'}`}
        width={80}
        rules={[{ validator: validateNumberInput({ min: 1 }), message: 'Duration is invalid' }]}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: `${val} ${val > 1 ? 'days' : 'day'}`,
      style: 'tableCell',
    }),
    csvFormat: (val) => `${val} ${val > 1 ? 'days' : 'day'}`,
    width: 100,
  },
  Progress: {
    label: 'Progress',
    property: 'progress',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Progress"
        type="number"
        value={value}
        task={record}
        min={0}
        max={100}
        property="progress"
        defaultValue={0}
        displayFormatter={(val) => `${val.toFixed(1)}%`}
        width={60}
        inputStyle={{
          padding: 0,
        }}
        rules={[{ validator: validateNumberInput({ min: 0, max: 100 }), message: 'Progress is invalid' }]}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: `${val.toFixed(1)}%`,
      style: 'tableCell',
    }),
    csvFormat: (val) => `${val.toFixed(1)}%`,
    width: 80,
  },
  Label: {
    label: 'Label',
    property: 'label',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => (
          <span style={{ color: value.color }}>{value.title}</span>
        )}
        width={80}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: val.title,
      style: 'tableCell',
      color: val.color,
    }),
    csvFormat: (val) => val.title || '',
    width: 100,
  },
  'Expected User Count': {
    label: 'Expected User Count',
    property: 'expectedNumberOfUsers',
    formatter: (value) => (
      <Popover
        placement="left"
        content={value}
      >
        <div style={{ width: 80, overflowX: 'hidden' }}>{value}</div>
      </Popover>
    ),
    width: 100,
  },
  'Actual User Count': {
    label: 'Actual User Count',
    property: 'usersColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => {
          const child = <div>{value.length}</div>;
          return createColumnPopover('Users', value, child);
        }}
        width={80}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Users', value),
    pdfFormat: (val) => ({
      ul: val.map((user) => user.name),
    }),
    csvFormat: (val) => val.map((user) => user.name).join(','),
    width: 100,
  },
  'Actual/Expected User Count': {
    label: 'Actual/Expected User Count',
    property: 'actualExpectedUserCount',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => {
          const child = <div style={{ color: value.color }}>{value.label}</div>;
          return createColumnPopover('Users', value.users, child);
        }}
        width={80}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Users', value.users),
    pdfFormat: (val) => ([
      {
        text: val.label,
        color: val.color,
      },
      {
        ul: val.users.map((user) => user.name),
      },
    ]),
    csvFormat: (val) => val.users.map((user) => user.name).join(','),
    width: 110,
  },
  Equipment: {
    label: 'Equipment',
    property: 'equipmentColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => {
          const child = <div>{value.length}</div>;
          return createColumnPopover('Equipment', value, child);
        }}
        width={80}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Equipment', value),
    pdfFormat: (val) => ({
      ul: val.map((equipment) => equipment.name),
    }),
    csvFormat: (val) => val.map((equipment) => equipment.name).join(','),
    width: 80,
  },
  Materials: {
    label: 'Materials',
    property: 'materialColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => {
          const child = <div>{value.length}</div>;
          return createColumnPopover(
            'Materials',
            value,
            child,
            (item) => `${item.name}: ${item.quantity}`,
          );
        }}
        width={80}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Materials', value, (item) => `${item.name}: ${item.quantity}`),
    pdfFormat: (val) => ({
      ul: val.map((material) => `${material.name}: ${material.quantity}`),
    }),
    csvFormat: (val) => val.map((material) => `${material.name}: ${material.quantity}`).join(','),
    width: 80,
  },
  Phase: {
    label: 'Phase',
    property: 'phaseColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => (value && value.length ? value[0].name : '')}
        width={80}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: val && val.length ? val[0].name : '',
      style: 'tableCell',
    }),
    csvFormat: (val) => (val && val.length ? val[0].name : ''),
    width: 100,
  },
  'Cost Codes': {
    label: 'Cost Codes',
    property: 'costCodeColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={() => {
          const child = <div>{value.length}</div>;
          return createColumnPopover('Cost Codes', value, child);
        }}
        width={80}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Cost Codes', value),
    pdfFormat: (val) => ({
      ul: val.map((costCode) => costCode.name),
    }),
    csvFormat: (val) => val.map((costCode) => costCode.name).join(','),
    width: 100,
  },
  'Actual Start Date': {
    label: 'Actual Start',
    property: 'actualStartDate',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Actual Start Date"
        type="date"
        value={value ? moment.utc(value * 1000) : null}
        task={record}
        property="actualStartDate"
        displayFormatter={dateFormatter}
        format="YYYY/M/D"
        allowClear={false}
        areEqual={areDatesEqual}
        width={90}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: dateFormatter(val ? moment.utc(val * 1000) : null),
      style: 'tableCell',
    }),
    csvFormat: (val) => dateFormatter(val ? moment.utc(val * 1000) : null),
    width: 110,
  },
  'Actual End Date': {
    label: 'Actual End',
    property: 'actualEndDate',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Actual End Date"
        type="date"
        value={value ? moment.utc(value * 1000) : null}
        task={record}
        property="actualEndDate"
        displayFormatter={dateFormatter}
        format="YYYY/M/D"
        allowClear={false}
        areEqual={areDatesEqual}
        width={90}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: dateFormatter(val ? moment.utc(val * 1000) : null),
      style: 'tableCell',
    }),
    csvFormat: (val) => dateFormatter(val ? moment.utc(val * 1000) : null),
    width: 110,
  },
  'Actual/Expected Start': {
    label: 'Actual/Expected Start',
    property: 'actualExpectedStart',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={dateDifferenceFormatter}
        width={80}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: getDateDifference(val),
      style: 'tableCell',
      color: val > 0 ? 'red' : 'green',
    }),
    csvFormat: (val) => getDateDifference(val),
    width: 120,
  },
  'Actual/Expected End': {
    label: 'Actual/Expected End',
    property: 'actualExpectedEnd',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        displayFormatter={dateDifferenceFormatter}
        width={80}
        activeTab={activeTab}
      />
    ),
    pdfFormat: (val) => ({
      text: getDateDifference(val),
      style: 'tableCell',
      color: val > 0 ? 'red' : 'green',
    }),
    csvFormat: (val) => getDateDifference(val),
    width: 120,
  },
  'Parent Dependencies': {
    label: 'Parent Dependencies',
    property: 'parentDependenciesColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Parent Dependencies"
        type="select"
        selectSource={tasks}
        selectSourceFilter={(task) => task.id !== record.id}
        value={record.parentDependencies}
        task={record}
        property="parentDependencies"
        mode="multiple"
        displayFormatter={() => {
          const child = <div>{value.length}</div>;
          return createColumnPopover('Parent Dependencies', value, child);
        }}
        allowClear
        optionFilterProp="label"
        areEqual={areArraysEqual}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Parent Dependencies', value),
    pdfFormat: (val) => ({
      ul: val.map((parentDependency) => parentDependency.name),
    }),
    csvFormat: (val) => val.map((parentDependency) => parentDependency.name).join(','),
    width: 120,
  },
  'Child Dependencies': {
    label: 'Child Dependencies',
    property: 'childDependenciesColumn',
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Child Dependencies"
        type="select"
        selectSource={tasks}
        selectSourceFilter={(task) => task.id !== record.id}
        value={record.childDependencies}
        task={record}
        property="childDependencies"
        mode="multiple"
        displayFormatter={() => {
          const child = <div>{value.length}</div>;
          return createColumnPopover('Child Dependencies', value, child);
        }}
        allowClear
        optionFilterProp="label"
        areEqual={areArraysEqual}
        activeTab={activeTab}
      />
    ),
    listFormat: (value) => createListDisplay('Child Dependencies', value),
    pdfFormat: (val) => ({
      ul: val.map((childDependency) => childDependency.name),
    }),
    // join array of strings with comma, but the comma should not count in a csv
    csvFormat: (val) => val.map((childDependency) => childDependency.name).join(','),
    width: 120,
  },
  Notes: {
    label: 'Notes',
    property: 'notes',
    width: 250,
    formatter: (value, record) => (
      <GanttScheduleTableInput
        title="Update Notes"
        value={value}
        task={record}
        property="notes"
        displayFormatter={() => (
          <Popover
            placement="left"
            content={value}
            overlayStyle={{
              width: '250px',
            }}
          >
            <div style={{ width: 250 }}>
              {value}
            </div>
          </Popover>
        )}
        width={230}
        activeTab={activeTab}
      />
    ),
  },
  Schedule: {
    label: 'Schedule',
    property: 'schedule',
    width: 150,
    formatter: (value, record) => (
      <GanttScheduleTableInput
        value={value}
        task={record}
        readOnly
        width={130}
        activeTab={activeTab}
      />
    ),
  },
});

export const convertToClientModel = (data) => data.map(
  (datum) => ({
    start: new Date(datum.startDate * 1000 + (new Date().getTimezoneOffset() * 60 * 1000)),
    end: new Date(datum.endDate * 1000 + (new Date().getTimezoneOffset() * 60 * 1000)),
    actualStartDate: new Date(
      datum.actualStartDate * 1000 + (new Date().getTimezoneOffset() * 60 * 1000),
    ),
    actualEndDate: new Date(
      datum.actualEndDate * 1000 + (new Date().getTimezoneOffset() * 60 * 1000),
    ),
    ...datum,
    styles: {
      backgroundColor: datum.color,
      backgroundSelectedColor: datum.color,
    },
  }),
);

export const calculateNewEndDate = (date, days, workingDays, holidays) => {
  let resultingDay = DateTime.fromSeconds(date, { zone: 'utc' });
  const validDaysInWeekMap = new Set(workingDays.length
    ? workingDays
    : defaultWorkingDays);
  const holidayMap = new Set(holidays.map((holiday) => holiday.date));

  // Calculate the end date of task, based on
  // start date, duration, holidays, and working days
  for (let i = days; i > 1; i -= 1) {
    resultingDay = resultingDay.plus({ days: 1 });
    // If the resulting day is not a working day, add a day as work cannot be done
    // Or if the resulting day is a holiday, add a day as work cannot be done
    if (
      !validDaysInWeekMap.has(resultingDay.weekday)
      || holidayMap.has(resultingDay.toSeconds())
    ) {
      i += 1;
    }
  }

  return resultingDay.plus({ hours: 23, minute: 59 });
};

// Calculate the duration of task, based on
// start date, end date, holidays, and working days
export const calculateDuration = (startDate, endDate, workingDays, holidays) => {
  let day = DateTime.fromSeconds(startDate, { zone: 'utc' });
  let duration = 1;

  const endDay = DateTime.fromSeconds(endDate, { zone: 'utc' }).minus({ hours: 23, minute: 59 });
  const validDaysInWeekMap = new Set(workingDays.length
    ? workingDays
    : defaultWorkingDays);
  const holidayMap = new Set(holidays.map((holiday) => holiday.date));

  while (day <= endDay) {
    day = day.plus({ days: 1 });

    // If the resulting day is a working day and not a holiday
    // add a day as work will be done on this day
    if (
      validDaysInWeekMap.has(day.weekday)
      && !holidayMap.has(day.toSeconds())
    ) {
      duration += 1;
    }
  }

  return duration;
};

/**
 * Returns the overlap between two date ranges
 * Ensuring that the overlap is within the enforced range.
 * @function findDateRangeOverlap
 * @param {Object} range1 - start and end date of first range in seconds
 * @param {Object} range2 - enforced start and end date of first range in seconds
 * @returns {Object}
 */
export const findDateRangeOverlap = (range1, range2) => {
  if (!range1) {
    return range2;
  }

  if (!range2) {
    return range1;
  }

  // If range1 overlaps on the left side of range 2
  if (range1.start >= range2.start && range1.start <= range2.end) {
    return {
      start: range1.start,
      end: range2.end > range1.end ? range1.end : range2.end,
    };
  }

  // If range 1 overlaps on the right side of range 2
  if (range1.end >= range2.start && range1.end <= range2.end) {
    return {
      start: range2.start,
      end: range2.end > range1.end ? range1.end : range2.end,
    };
  }

  // If range 1 is completely inside range 2
  if (range1.start <= range2.start && range1.end >= range2.end) {
    return range2;
  }

  return null;
};

export const sortHolidays = (holidays) => holidays.sort((a, b) => {
  if (a.date < b.date) return -1;
  if (a.date > b.date) return 1;
  return 0;
});

export const parseSettings = (settings) => {
  if (!settings) {
    return {
      workingDays: defaultWorkingDays,
    };
  }

  return {
    ...settings,
    workingDays: settings.workingDays
      ? JSON.parse(settings.workingDays)
      : defaultWorkingDays,
  };
};

export const calculateOverlap = ({
  dateRange,
  taskId,
  tasks,
  users,
  equipment,
}) => {
  const overlappedTasks = {
    overlappingEquipment: [],
    overlappingUsers: [],
  };

  const rowMapping = {
    users: new Set(users),
    equipment: new Set(equipment),
  };

  tasks.forEach((task) => {
    const dateRangeOverlap = findDateRangeOverlap(
      dateRange,
      {
        start: task.startDate,
        end: task.endDate,
      },
    );

    if (!dateRangeOverlap || task.id === taskId) {
      return;
    }

    const overlappedUsers = task.users.filter((user) => rowMapping.users.has(user));
    const overlappedEquipment = task.equipment.filter(
      (equipmentItem) => rowMapping.equipment.has(equipmentItem),
    );

    if (overlappedUsers.length) {
      overlappedTasks.overlappingUsers.push({
        ...task,
        overlapStart: dateRangeOverlap.start,
        overlapEnd: dateRangeOverlap.end,
        overlapUsers: overlappedUsers,
      });
    }

    if (overlappedEquipment.length) {
      overlappedTasks.overlappingEquipment.push({
        ...task,
        overlapStart: dateRangeOverlap.start,
        overlapEnd: dateRangeOverlap.end,
        overlapEquipment: overlappedEquipment,
      });
    }
  });

  return overlappedTasks;
};

/**
 * Determines if filters are active given an a filter object
 * @function isFilterActive
 * @param {Object} filters - filter object
 * @returns {boolean}
 */
export const isFilterActive = (filters) => {
  let isActive = false;
  const keysToIgnore = [
    'filterView',
    'filterViewName',
  ];

  Object.keys(filters).forEach((key) => {
    if (keysToIgnore.includes(key)) {
      return;
    }

    if (filters[key]) {
      if (!Array.isArray(filters[key])) {
        isActive = true;
      }

      if (filters[key].length) {
        isActive = true;
      }
    }
  });
  return isActive;
};

/**
 * Determines if a row should be filtered based on a filter map
 * @function filterOnProperty
 * @param {*} rowPropertyValue - the property value to filter on
 * @param {*} filterMap - the filter map to check against
 * @returns {boolean}
 */
export const filterOnProperty = (rowPropertyValue, filterMap) => {
  if (!filterMap.size) {
    return true;
  }

  if (Array.isArray(rowPropertyValue)) {
    return rowPropertyValue.some((propertyValue) => filterMap.has(propertyValue));
  }

  return filterMap.has(rowPropertyValue);
};

export const constructCostCodeTreeData = ({
  costCodes,
  costCodeMap,
  phaseMap,
  phaseIds,
  t,
}) => {
  const treeData = [];
  const baseNodes = phaseIds && phaseIds.length
    ? phaseIds
    : Object.keys(phaseMap);

  baseNodes.forEach((item) => {
    const phases = phaseMap[item];
    const phaseNode = {
      children: [],
      selectable: false,
    };

    if (!phases) {
      return;
    }

    phases.forEach((phase) => {
      const costCode = costCodeMap[phase.costcodeId];
      phaseNode.title = phase.name;
      phaseNode.value = phase.id;

      if (costCode) {
        phaseNode.children.push({
          value: `${phase.id}.${costCode.id}`,
          title: costCode.name,
          isLeaf: true,
        });
      }
    });

    treeData.push(phaseNode);
  });

  if (!phaseIds || !phaseIds.length) {
    const projectCostCodes = [];
    const globalCostCodes = [];

    costCodes.forEach((costCode) => {
      const formattedCostCode = {
        value: `${''}.${costCode.id}`,
        title: costCode.name,
        isLeaf: true,
      };

      if (!costCode.phased && costCode.type !== 'global') {
        projectCostCodes.push(formattedCostCode);
      } else {
        globalCostCodes.push(formattedCostCode);
      }
    });

    if (projectCostCodes.length) {
      treeData.push({
        title: `Unphased ${t('Project')}`,
        value: -1,
        selectable: false,
        children: projectCostCodes,
      });
    }

    if (globalCostCodes.length) {
      treeData.push({
        title: 'Global',
        value: -2,
        selectable: false,
        children: globalCostCodes,
      });
    }
  }

  return treeData;
};

export const getPhaseOptions = (phaseMap) => {
  const options = [];

  // We limit the available phases to the ones that are selected in the cost codes
  Object.keys(phaseMap).forEach((item) => {
    const phaseCostCodes = phaseMap[item];

    if (phaseCostCodes.length) {
      options.push({
        value: phaseCostCodes[0].id,
        label: `${phaseCostCodes[0].name} - ${phaseCostCodes[0].description}`,
      });
    }
  });

  return options;
};

export const ganttColumns = [
  'Name',
  'Start Date',
  'End Date',
  'Progress',
  'Duration',
  'Label',
  'Expected User Count',
  'Actual User Count',
  'Actual/Expected User Count',
  'Equipment',
  'Phase',
  'Cost Codes',
  'Materials',
  'Actual Start Date',
  'Actual End Date',
  'Actual/Expected Start',
  'Actual/Expected End',
  'Parent Dependencies',
  'Child Dependencies',
  'Notes',
  'Schedule',
];

export const escapeValueForCSV = (value) => {
  let adjustedValue = value;

  if (value && typeof value === 'string') {
    // Need to escape quotes with double quotes in csv files
    adjustedValue = value.replace(/"/g, '""');
  }

  // If the value contains a comma, newline, or double quote, we need to wrap it in double quotes
  return `"${adjustedValue}",`;
};

export const getScheduleTreeData = (t, schedules) => {
  if (!schedules.length) {
    return [];
  }

  const nodes = {
    masterNode: {
      title: 'Master Schedule',
      value: -1,
      selectable: true,
    },
    scheduleNode: {
      title: 'Schedules',
      selectable: false,
      value: -2,
      children: [],
    },
    projectNode: {
      title: `${t('Project')} Schedules`,
      selectable: false,
      value: -3,
      children: [],
    },
  };

  schedules.forEach((schedule) => {
    if (schedule.isMaster) {
      nodes.masterNode.value = `.${schedule.id}`;
      return;
    }

    if (schedule.project) {
      nodes.projectNode.children.push({
        title: schedule.project.name,
        value: `${schedule.project.id}.${schedule.id}`,
        selectable: true,
      });
    } else {
      nodes.scheduleNode.children.push({
        title: schedule.name,
        value: `.${schedule.id}`,
        selectable: true,
      });
    }
  }, []);

  const result = [];

  // Remove any nodes that don't have children (except Master node)
  Object.values(nodes).forEach((node) => {
    if (!node.children || node.children.length) {
      result.push(node);
    }
  });

  return result;
};

export const importFile = (file, notification) => new Promise((resolve) => {
  if (!file) {
    return;
  }

  if (file.name.includes('.gan')) {
    // Properties to extract from the XML
    const relevantTaskProps = [
      'name',
      'color',
      'start',
      'duration',
      'complete',
      'thirdDate',
      'thirdDate-constraint',
      'priority',
      'type',
      'id',
    ];

    const days = [
      'sun',
      'mon',
      'tue',
      'wed',
      'thu',
      'fri',
      'sat',
    ];

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = (evt) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(evt.target.result, 'application/xml');
      const tasks = doc.getElementsByTagName('task');
      const workingDays = doc.getElementsByTagName('default-week');
      const dates = doc.getElementsByTagName('date');
      const taskWorkingDays = [];
      const importedTasks = [];
      const holidays = [];

      if (dates && dates.length) {
        // eslint-disable-next-line
        for (let i = 0; i < dates.length; i++) {
          const date = dates[i];
          const dateProps = date.attributes;

          if (dateProps.type && dateProps.type.value === 'HOLIDAY') {
            const holiday = {
              id: i,
              date: DateTime.utc(
                parseInt(dateProps.year.value, 10),
                parseInt(dateProps.month.value, 10),
                parseInt(dateProps.date.value, 10),
              ).toSeconds(),
              name: date.textContent,
            };

            holidays.push(holiday);
          }
        }
      }

      if (workingDays && workingDays.length) {
        days.forEach((day, index) => {
          if (!parseInt(workingDays[0].attributes[day].value, 10)) {
            // Sunday is 7 in luxon, Monday is 1
            if (!index) {
              taskWorkingDays.push(7);
              return;
            }

            taskWorkingDays.push(index);
          }
        });
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const task of tasks) {
        const taskProps = task.attributes;
        const importedTask = {};

        relevantTaskProps.forEach((relevantTaskProp) => {
          importedTask[relevantTaskProp] = taskProps[relevantTaskProp]
            ? taskProps[relevantTaskProp].value
            : 0;
        });

        if (task.children) {
          const notes = task.getElementsByTagName('notes');

          if (notes.length) {
            importedTask.notes = notes[0].textContent;
          }
        }

        importedTask.workingDays = taskWorkingDays;
        importedTasks.push(importedTask);
      }

      if (importedTasks.length) {
        resolve({
          tasks: importedTasks,
          holidays,
          workingDays: taskWorkingDays,
        });
      } else {
        notification.warn({
          key: 'import',
          message: 'Warning',
          description: 'No tasks found in the file.',
        });
      }
    };
  } else {
    notification.warn({
      key: 'import',
      message: 'Error',
      description: 'Unsupported file type, only files with extension .gan are supported',
    });
  }
});

export const getFilteredUsers = ({
  users,
  divisions,
  selectedDivisions,
  selectedUsers = new Set(),
}) => {
  const selectedDivUsers = Object
    .values(divisions)
    .filter(({ id: divisionId }) => selectedDivisions.has(divisionId))
    .map(({ users: divUsers = new Set() }) => divUsers);

  return users.filter(({ id, active }) => (
    (selectedDivUsers.some((divUser) => divUser.has(id)) && active)
    || selectedUsers.has(id)
  ));
};

export const getFilteredCostCodes = ({
  costCodes,
  selectedDivisions,
  selectedProjects = new Set(),
  selectedCostCodes = new Set(),
}) => costCodes.filter(
  (costCode) => (
    selectedCostCodes.has(costCode.id) || (
      selectedDivisions.has(costCode.divisionId)
        && costCode.active
        && (
          selectedProjects.has(costCode.projectId)
          || (!costCode.projectId && costCode.type === 'global')
        )
    )
  ),
);

export const getFilteredEquipment = ({
  equipment,
  selectedDivisions,
  selectedEquipment,
}) => equipment.filter(
  (equip) => (
    selectedEquipment.has(equip.id) || (
      equip.active
      && equip.divisionIds.some((divisionId) => selectedDivisions.has(divisionId))
    )
  ),
);

export const getFilteredMaterials = ({
  materials,
  selectedDivisions,
  selectedMaterials,
}) => Object
  .values(materials)
  .filter((material) => (
    selectedMaterials.has(material.id) || (
      selectedDivisions.has(material.divisionId) && material.active
    )
  ));

export const getFilteredPhaseMap = ({
  phases,
  selectedProjects,
  selectedPhases = new Set(),
}) => phases.reduce((acc, phase) => {
  if (!selectedPhases.has(phase.id) && !selectedProjects.has(phase.projectId)) {
    return acc;
  }

  if (!acc[phase.id]) {
    acc[phase.id] = [];
  }

  acc[phase.id].push(phase);
  return acc;
}, {});

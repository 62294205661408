import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import {
  customTableRow,
  getCustomFieldMap,
  getFormattedListViewTask,
  getListViewDayColumns,
  getListViewExpandedColumns,
} from './timecardListSummaryView.helpers';
import { getIdMap } from '../helpers/helpers';
import { sortByStartTime } from '../helpers/tasks';

export default function TimeCardsListView({
  days,
  onView,
  onEdit,
  onFormClick,
  isApprovals,
  userId,
  entryDataMap,
}) {
  const { t } = useTranslation();
  const {
    costcodes: costCodes,
    phases,
  } = useSelector((state) => state.costcodes);
  const {
    roundingInterval,
    enableHourBasedTracking,
    roundingType,
    roundingSetting,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            roundingType: roundingTypeSetting,
            roundingInterval: roundingIntervalSetting,
            enableHourBasedTracking: enableHourBasedTrackingSetting = false,
            roundingSetting,
          } = {},
        } = {},
      } = {},
    } = state;
    return {
      roundingInterval: roundingIntervalSetting || 1,
      enableHourBasedTracking: enableHourBasedTrackingSetting,
      roundingType: roundingTypeSetting,
      roundingSetting,
    };
  });
  const unions = useSelector((state) => state.unions.unions);
  const unionClasses = useSelector((state) => state.unions.classes);
  const unionLocals = useSelector((state) => state.unions.locals);
  const customFields = useSelector((state) => state.timecards.customFields);
  const users = useSelector((state) => state.users.users);

  const [selectedDayEntryColumns, setSelectedDayEntryColumns] = useState();
  const [selectedTimeCardEntryColumns, setSelectedTimeCardEntryColumns] = useState();
  const [selectedCustomFieldColumns, setSelectedCustomFieldColumns] = useState();

  const userMap = useMemo(() => getIdMap(users), [users]);
  const unionMap = useMemo(() => getIdMap(unions), [unions]);
  const unionClassMap = useMemo(() => getIdMap(unionClasses), [unionClasses]);
  const unionLocalMap = useMemo(() => getIdMap(unionLocals), [unionLocals]);
  const costCodeMap = useMemo(() => getIdMap(costCodes), [costCodes]);
  const phaseMap = useMemo(() => phases.reduce((acc, phase) => {
    if (!acc[phase.id]) {
      acc[phase.id] = [];
    }

    acc[phase.id].push(phase);
    return acc;
  }, {}), [phases]);

  const customFieldMap = useMemo(() => getCustomFieldMap(customFields), [customFields]);

  useEffect(() => {
    const onStorageUpdated = (e) => {
      if (!e || e.detail === 'timeCardDayEntryColumns') {
        const rawDayEntryColumns = window.localStorage.getItem('timeCardDayEntryColumns');
        if (rawDayEntryColumns) {
          setSelectedDayEntryColumns(JSON.parse(rawDayEntryColumns));
        }
      }

      if (!e || e.detail === 'timeCardEntryColumns') {
        const rawTimeCardEntryColumns = window.localStorage.getItem('timeCardEntryColumns');
        if (rawTimeCardEntryColumns) {
          setSelectedTimeCardEntryColumns(JSON.parse(rawTimeCardEntryColumns));
        }
      }

      if (!e || e.detail === 'timeCardCustomFieldColumns') {
        const rawCustomFieldColumns = window.localStorage.getItem('timeCardCustomFieldColumns');
        if (rawCustomFieldColumns) {
          setSelectedCustomFieldColumns(JSON.parse(rawCustomFieldColumns));
        }
      }
    };

    onStorageUpdated();
    window.addEventListener('localStorageSetItem', onStorageUpdated);

    return () => {
      window.removeEventListener('localStorageSetItem', onStorageUpdated);
    };
  }, []);

  const dayEntryColumns = useMemo(() => (
    getListViewDayColumns({
      roundingInterval,
      selectedDayEntryColumns,
      isApprovals,
      onEdit,
      userId,
      roundingType,
      roundingSetting,
    })
  ), [
    onEdit,
    selectedDayEntryColumns,
    isApprovals,
    userId,
    roundingInterval,
    roundingType,
    roundingSetting,
  ]);

  const dayEntryData = useMemo(() => {
    const dayArray = Object.keys(days);
    return dayArray.map((day) => {
      const dayData = days[day];
      const dayTasks = [...(dayData?.dayTasks ?? [])].sort(sortByStartTime) ?? [];
      return {
        key: day,
        ...dayData,
        dayTasks,
      };
    });
  }, [days]);

  const formatTask = useCallback((task) => (
    getFormattedListViewTask({
      task,
      costCodeMap,
      phaseMap,
      enableHourBasedTracking,
      unionClassMap,
      unionLocalMap,
      unionMap,
      entryDataMap,
      userMap,
    })
  ), [
    costCodeMap,
    phaseMap,
    unionMap,
    unionClassMap,
    unionLocalMap,
    enableHourBasedTracking,
    entryDataMap,
    userMap,
  ]);

  const expandedRowRender = useCallback((record) => {
    const seen = new Set();
    const formattedTasks = record.dayTasks
      .filter((task) => !task.id || (!seen.has(task.id) && seen.add(task.id)))
      .map((task) => formatTask(task));
    const divisionIds = new Set(formattedTasks.map((task) => task.divisionId));

    const relevantColumns = getListViewExpandedColumns({
      t,
      selectedTimeCardEntryColumns,
      selectedCustomFieldColumns,
      customFieldMap,
      divisionIds,
      onFormClick,
    });

    return (
      <Table
        columns={relevantColumns}
        dataSource={formattedTasks}
        pagination={false}
        onRow={(rowRecord) => ({
          onClick: () => onView(rowRecord.key),
          isPending: rowRecord.isPending,
          formUserName: rowRecord.formUserName,
          formCreatedAt: rowRecord.formCreatedAt,
          formName: rowRecord.formName,
          formNumber: rowRecord.formNumber,
        })}
        rowClassName={() => 'timecard-entry-row'}
        size="small"
        bordered
        components={{
          body: {
            row: customTableRow,
          },
        }}
      />
    );
  }, [
    onView,
    formatTask,
    customFieldMap,
    selectedTimeCardEntryColumns,
    selectedCustomFieldColumns,
    onFormClick,
  ]);

  return (
    <Table
      columns={dayEntryColumns}
      dataSource={dayEntryData}
      expandable={{ expandedRowRender }}
      size="small"
      onRow={(entry) => ({
        onClick: () => onView(entry.key),
      })}
      pagination={{ pageSize: 25, position: ['topRight'], hideOnSinglePage: true }}
      rowClassName={() => 'timecard-entry-row'}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardsListView.propTypes = {
  days: PropTypes.object,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isApprovals: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  entryDataMap: PropTypes.object.isRequired,
  onFormClick: PropTypes.func.isRequired,
};

TimeCardsListView.defaultProps = {
  days: {},
};

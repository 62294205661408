import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  addEdge,
} from 'react-flow-renderer';
import { DateTime } from 'luxon';

import WorkflowAddSlider from './WorkflowAddSlider';
import WorkflowTriggerNode from './WorkflowTriggerNode';
import WorkflowApproverNode from './WorkflowApproverNode';
import WorkflowEditNode from './WorkflowEditNode';
import WorkflowEmailNode from './WorkflowEmailNode';
import WorkflowPushNode from './WorkflowPushNode';
import WorkflowPDFNode from './WorkflowPDFNode';
import WorkflowUserNode from './WorkflowUserNode';
import WorkflowCompleteNode from './WorkflowCompleteNode';
import WorkflowFormTriggerNode from './WorkflowFormTriggerNode';
import WorkflowDispatchNode from './WorkflowDispatchNode';
import WorkflowStatusNode from './WorkflowStatusNode';
import WorkflowQuickBooksInvoice from './WorkflowQuickBooksSync';
import WorkflowPaymentNode from './WorkflowPaymentNode';
import WorkflowUpdateContractNode from './WorkflowUpdateContractNode';
import WorkflowMaterialDebitStep from './WorkflowMaterialDebitStep';
import WorkflowLogicalYesNoNode from './WorkflowLogicalYesNoNode';
import WorkflowCreateTimeNode from './WorkflowCreateTimeNode';
import WorkflowCreateProfileNode from './WorkflowCreateProfileNode';
import WorkflowLogicalLoopNode from './WorkflowLogicalLoopNode';
import WorkflowFormUpdateNode from './WorkflowFormUpdateNode';
import WorkflowCreateBoardCardNode from './WorkflowCreateBoardCardNode';
import WorkflowCardUpdateNode from './WorkflowCardUpdateNode';
import WorkflowUpdateCostNode from './WorkflowUpdateCostNode';
import WorkflowExternalSignatureNode from './WorkflowExternalSignatureNode';

import { DEFAULT_TRIGGER_DAYS } from './formTrigger.constants';

import WorkflowEdge from './WorkflowEdge';
import FormWorkflowContext from './FormWorkflowContext';

import {
  FORMS_ADD_WORKFLOW_TYPE,
  FORMS_WORKFLOW_STEP_PREFIX,
  FORMS_WORKFLOW_STEP_1,
} from '../../nux/nux.constants';

import {
  createNuxEntry,
} from '../../nux/state/nux.actions';

import NuxFocusBackground from '../../nux/NuxFocusBackground';

import colors from '../../constants/Colors';

const getId = () => `ot_${Math.floor(new Date())}`;

const defaultElements = [
  {
    id: 'trigger',
    type: 'trigger',
    position: { x: 250, y: 25 },
  },
  {
    id: 'user',
    type:'user',
    position: { x: 250, y: 350 },
  },
  {
    id: 'complete',
    type:'complete',
    position: { x: 300, y: 550 },
    data: { label:'User Completes Form' },
  },
  {
    id: 'trigger-user',
    source: 'trigger',
    target: 'user',
    arrowHeadType:'arrow',
    type:'smoothstep',
    style:{ strokeWidth: 2 },
  },
  {
    id: 'user-complete',
    source: 'user',
    target: 'complete',
    arrowHeadType:'arrow',
    type:'smoothstep',
    style:{ strokeWidth: 2 },
  },
];

const elementsToWorkflow = (elements = [], dataMap = {}) => {
  const nodes = [];
  const edges = [];
  elements.forEach((element) => {
    const {
      id,
      type,
      source,
      sourceHandle,
      target,
      targetHandle,
      position,
    } = element;
    if(type === 'customEdge' || type === 'smoothstep') {
      // Edge
      const newEdge = {
        source,
        target,
      };
      if(sourceHandle) newEdge.sourceHandle = sourceHandle;
      if(targetHandle) newEdge.targetHandle = targetHandle;
      edges.push(newEdge);
    } else if(type !== 'trigger' && type !== 'complete') {
      nodes.push({
        id,
        type,
        position,
        data:dataMap[id],
      });
    }
  });
  return { nodes, edges };
};

const workflowToElements = ({
  nodes = [],
  edges = [],
  triggers = [],
  isExternalForm,
}) => {
  const newDataMap = {};
  let elements = [defaultElements[0],defaultElements[2]];

  elements = elements.concat(
    edges.map(({ source, sourceHandle, target, targetHandle }) => ({
      id: `${source}-${target}`,
      source,
      sourceHandle,
      target,
      targetHandle,
      arrowHeadType: 'arrow',
      type: source === 'trigger' || source === 'user' ? 'smoothstep' : 'customEdge',
      style: { strokeWidth: 2 },
    })),
  );

  nodes.forEach((node) => {
    const { id, data } = node;
    let nodeData = data;

    // Update user node to remove external user if not external form
    if (id === 'user') {
      if (!isExternalForm && nodeData?.users.includes('external')) {
        nodeData = {
          ...nodeData,
          users: [],
        };
      } else if (isExternalForm) {
        // Add external user if external form
        nodeData = {
          ...nodeData,
          users: ['external'],
        };
      }
    }

    const formattedNode = {
      ...node,
      data: nodeData,
    };
    newDataMap[id] = nodeData;
    elements.push(formattedNode);
  });

  let newTriggers = triggers;

  // Fix triggers if external form
  const hasExternalTrigger = triggers.includes('external');
  if (isExternalForm && !hasExternalTrigger) {
    newTriggers = ['external'];
  } else if (!isExternalForm && hasExternalTrigger) {
    newTriggers = ['manual'];
  }

  return {
    triggers: newTriggers,
    elements,
    newDataMap,
  };
};

const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

export default function FormWorkflows ({
  name,
  templateId,
  formRef,
  isDisplay,
  isAdd,
  collected,
  sections,
  exportLocation,
  onWorkflowChange,
  currentStep,
  divisionId,
  workflow = {},
  drawOptions = [],
  fileMap = {},
  currentFormData = {},
  projectId,
  useStandardTemplate,
  isExternalForm,
  libraryTemplateStatusesToAdd,
}) {
  const {
    typeId,
  } = currentFormData;
  const dispatch = useDispatch();
  const reactFlowWrapper = useRef(null);

  const defaultTrigger = isExternalForm ? 'external' : 'manual';

  const nux = useSelector(state => state.nux.nux);
  const activeNuxAction = useSelector(state => state.nux.activeNuxAction);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState(defaultElements);
  const [triggers,setTriggers] = useState([defaultTrigger]);
  const [triggerDays,setTriggerDays] = useState(DEFAULT_TRIGGER_DAYS);
  const [dataMap,setDataMap] = useState({});
  // We can't reset node configurations after initial render of workflow graph
  // So we need to update the key to force a reset with new divisionId or new sections
  const [workflowKey,setWorkflowKey] = useState();

  const onConnect = (params) => setElements((els) => addEdge({
    ...params,
    type:'customEdge',
    arrowHeadType:'arrow',
    id: getId(),
    style:{
      strokeWidth: 2,
    }
  }, els))
  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const DEFAULT_EMAIL = 'forms@ontraccr.com';
  const DEFAULT_BODY = 'Completed form attached.\n\nSent by Ontraccr.';
  const DEFAULT_SUBJECT = `${name} form submitted`;

  const defaultDataMap = {
    email: { sender: DEFAULT_EMAIL, subject: DEFAULT_SUBJECT, text: DEFAULT_BODY },
    push: { message: '', messageField: undefined },
  };

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const clientX = event.dataTransfer.getData('application/reactflow/clientX');
    const clientY = event.dataTransfer.getData('application/reactflow/clientY');
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - clientX,
      y: event.clientY - reactFlowBounds.top - clientY,
    });

    const defaultData = defaultDataMap[type];
    const id = getId();
    const newNode = {
      id,
      type,
      position,
      data: { label: `${type} node`, ...defaultData },
    };
    if (defaultData){
      setDataMap((dataMap) => {
        return {
          ...dataMap,
          [id]: defaultData,
        };
      })
    }

    setElements(elements.concat(newNode));
  };


  const edgeTypes = {
    customEdge:WorkflowEdge({ setElements, isDisplay }),
  };

  const nodeTypes = {
    complete: WorkflowCompleteNode({ isExternalForm }),
    user: WorkflowUserNode({
      setDataMap,
      isDisplay,
      divisionId,
      isExternalForm,
    }),
    trigger: WorkflowTriggerNode({
      setTriggers,
      setTriggerDays,
      isDisplay,
      workflow,
      divisionId,
      isExternalForm,
    }),
    approval: WorkflowApproverNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      sections,
      isExternalForm,
    }),
    dispatch: WorkflowDispatchNode({
      setElements,
      setDataMap,
      isDisplay,
      sections,
      name,
    }),
    email: WorkflowEmailNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      sections,
      name,
      isExternalForm,
    }),
    createTime: WorkflowCreateTimeNode({
      setElements,
      setDataMap,
      isDisplay,
      sections,
      name,
      divisionId,
    }),
    status: WorkflowStatusNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateStatuses: libraryTemplateStatusesToAdd,
    }),
    push: WorkflowPushNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      sections,
      isExternalForm,
    }),
    updateContract: WorkflowUpdateContractNode({
      setElements,
      setDataMap,
      isDisplay,
      sections,
      name,
    }),
    materialDebit: WorkflowMaterialDebitStep({
      setElements,
      setDataMap,
      isDisplay,
      sections,
      name,
    }),
    pdf: WorkflowPDFNode({
      onNodeUpdate: setElements,
      collected,
      sections,
      formRef,
      exportLocation,
      setDataMap,
      isDisplay,
      drawOptions,
      fileMap,
      useStandardTemplate,
    }),
    formTrigger: WorkflowFormTriggerNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
      projectId,
      isExternalForm,
    }),
    createBoardCard: WorkflowCreateBoardCardNode({
      setElements,
      setDataMap,
      name,
      sections,
      divisionId,
      isDisplay,
      isExternalForm,
    }),
    createProfile: WorkflowCreateProfileNode({
      setElements,
      setDataMap,
      name,
      sections,
      divisionId,
      isDisplay,
    }),
    edit: WorkflowEditNode({
      setElements, setDataMap, isDisplay, divisionId, sections, isExternalForm,
    }),
    qbInvoice: WorkflowQuickBooksInvoice({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
      formTypeName: 'Invoice',
    }),
    qbPO: WorkflowQuickBooksInvoice({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
      formTypeName: 'PO',
    }),
    stripePayment: WorkflowPaymentNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
      isInvoice: true,
    }),
    logicalYesNo: WorkflowLogicalYesNoNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
    }),
    logicalLoop: WorkflowLogicalLoopNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
    }),
    formUpdate: WorkflowFormUpdateNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
    }),
    updateBoardCard: WorkflowCardUpdateNode({
      setElements,
      setDataMap,
      isDisplay,
      divisionId,
      templateId,
      sections,
      name,
    }),
    updateCost: WorkflowUpdateCostNode({
      isDisplay,
      setElements,
      setDataMap,
      sections,
      name,
    }),
    externalSignature: WorkflowExternalSignatureNode({
      isDisplay,
      setDataMap,
      setElements,
      sections,
      divisionId,
      isExternalForm,
    }),
  };

  useEffect(() => {
    setWorkflowKey(`${name}-${divisionId}-${isExternalForm}-${DateTime.local().toMillis()}`);
  },[sections,divisionId,name, isExternalForm]);

  useEffect(() => {
    const { elements:flowElements = [] } = reactFlowInstance ? reactFlowInstance.toObject() : {};
    const newWorkflow = {
      ...elementsToWorkflow(flowElements, dataMap),
      triggers,
      triggerDays,
    };
    onWorkflowChange(newWorkflow);
  },[elements, reactFlowInstance, onWorkflowChange, triggers, triggerDays, dataMap]);

  useEffect(() => {
    if(workflow?.triggers && workflow?.nodes && workflow?.edges) {
      const {
        triggers:initialTriggers,
        elements:initialElements,
        newDataMap,
      } = workflowToElements({ ...workflow, isExternalForm });
      setElements(initialElements);
      setTriggers(initialTriggers);
      setTriggerDays(workflow.triggerDays ?? DEFAULT_TRIGGER_DAYS);
      setDataMap(newDataMap);
      if(reactFlowInstance) reactFlowInstance.fitView({ padding: 0.30 });
    }
  },[workflow, isDisplay, reactFlowInstance, isExternalForm]);
  useEffect(() => {
    if(
      currentStep === 2
      && isAdd
      && dispatch
      && !nux.has(FORMS_ADD_WORKFLOW_TYPE)
      && reactFlowInstance
    ) {
      dispatch(createNuxEntry(FORMS_ADD_WORKFLOW_TYPE));
      // dispatch(startNuxAction(FORMS_WORKFLOW_STEP_1));
      reactFlowInstance.fitView({ padding: 0 });
    }
  },[nux,currentStep,dispatch, isAdd, reactFlowInstance]);

  const contextValue = useMemo(() => ({
    elements,
    dataMap,
  }), [elements, dataMap])

  const showNux = activeNuxAction && activeNuxAction.startsWith(FORMS_WORKFLOW_STEP_PREFIX);
  const isNuxStep1 = activeNuxAction === FORMS_WORKFLOW_STEP_1;

  return (
    <>
    <div
      className={
        isDisplay
        ? 'form-content-container'
        : 'form-content-container-editable'
      }
      style={{
        left:0,
        right: 0,
        borderTop: isDisplay ? 'none' : `1px ${colors.DIVIDER_COLOR} solid`
      }}
      ref={reactFlowWrapper}
    >
      <FormWorkflowContext.Provider value={contextValue}>
        <ReactFlowProvider>
            <ReactFlow
              key={workflowKey}
              style={{
                zIndex: showNux && !isNuxStep1 ? 1000 : 3,
              }}
              elements={elements}
              snapToGrid
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onLoad={onLoad}
              onConnect={onConnect}
              connectionLineType='smoothstep'
              connectionLineStyle={{
                strokeWidth:2,
              }}
              minZoom={0.2}
              nodesConnectable={!isDisplay && !showNux}
              nodesDraggable={!isDisplay && !showNux}
              elementsSelectable={!isDisplay && !showNux}
            >
              {!showNux && <Background variant='lines' />}
              {!showNux && <Controls showInteractive={false}/>}
            </ReactFlow>
        </ReactFlowProvider>
      </FormWorkflowContext.Provider>
      {!isDisplay && <WorkflowAddSlider sections={sections} typeId={typeId}/>}
    </div>
    <NuxFocusBackground show={showNux}/>
    </>
  );
};

import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Timeline } from 'antd';
import {
  MailOutlined,
  FileSearchOutlined,
  FilePdfOutlined,
  FormOutlined,
  LoadingOutlined,
  FlagTwoTone,
  MobileOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CalendarOutlined,
  TagOutlined,
  EditOutlined,
  EditFilled,
  ProjectFilled,
  CloudSyncOutlined,
  DollarOutlined,
  StopOutlined,
  GoldOutlined,
  BranchesOutlined,
  RetweetOutlined,
  GroupOutlined,
  RotateRightOutlined,
  IssuesCloseOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

import { resubmitEmail } from '../state/forms.actions';
import { getIdMap, currencyFormatter, isNullOrUndefined } from '../../helpers/helpers';
import Step from '../../common/containers/TimelineStep';
import TimelineStepText from '../../common/containers/TimelineStepText';
import FormUpdateStep from './FormUpdateStep';

const boardPrefix = (board) => board ? `${board}:` : '';
const statusTitle = (board, status) => bold(`${boardPrefix(board)}${status}`);
const bold = (text) => <span style={{ fontFamily: 'roboto-regular'}}>{text} </span>;
const Ontraccr = bold('Ontraccr');

export const parseMetadata = (metadata) => {
  try {
    return JSON.parse(metadata || '{}');
  } catch (err) {
    return {};
  }
};
const formatUserIds = ({
  userIds,
  userMap,
  defaultValue = () => null,
}) => (
  userIds.map((userId) => {
    if (!(userId in userMap)) return defaultValue(userId);
    return (
      <>
        {`- ${userMap[userId].name}`}
        <br />
      </>
    );
  })
);
const parseUsers = ({
  nodeMap,
  stepId,
  userMap,
  formResponseMap,
  metadataUsers,
  defaultValue = () => null,
}) => {
  const {
    [stepId]: {
      data: {
        users = [],
      } = {},
    } = {},
  } = nodeMap;

  let userIds = [];
  // Prior to 2.6.1
  // Users were not stored in the step metadata
  if (metadataUsers) {
    userIds = metadataUsers;
  } else {
    users.forEach((userIdOrFieldId) => {
      if (typeof userIdOrFieldId === 'string' && userIdOrFieldId.startsWith('field-')) {
        // References a field
        const {
          [userIdOrFieldId]: {
            values: responseUserIds = [],
          } = {},
        } = formResponseMap;
        userIds = userIds.concat(responseUserIds.map((r) => r.id));
      } else {
        userIds.push(userIdOrFieldId);
      }
    });
  }

  return formatUserIds({ userIds, userMap, defaultValue });
};

const sentMessage = ({ caller, names, text }) => (
  <div>
    {caller || Ontraccr}
    {text}
    <br />
    {names}
  </div>
);

const getEmailList = (email, order) => (
  <>
    {`- ${order ? `(${order}) ` : ''}${email}`}
    <br />
  </>
);

const PendingView = ({ isFinished }) => !isFinished
  ? <div style={{ fontWeight: 500 }}>Workflow In Progress</div> : null;
const PendingDot = ({ isFinished }) => !isFinished
  ? <LoadingOutlined style={{ color: 'black' }} /> : null;
const CompleteStep = ({ isFinished }) => (
  isFinished
  ? (
    <Timeline.Item
      dot={<FlagTwoTone twoToneColor="forestgreen" style={{ width: 5, height: 5 }} />}
      prefixCls="ot-timeline"
    >
      <div style={{ fontWeight: 500 }}>Workflow Complete</div>
    </Timeline.Item>
  )
  : null
);

export default function FormTimeline({
  userName,
  assignedUserNames = [],
  state: formState,
  templateSchema = {},
  steps = [],
  approvals = [],
  createdAt = 0,
  userMap = {},
  sections = [],
  formId = '',
  isFinished,
}) {
  const {
    workflow: {
      nodes = [],
    } = {},
    isExternalForm,
  } = templateSchema;
  const nodeMap = getIdMap(nodes);
  const dispatch = useDispatch();

  const templates = useSelector((state) => state.forms.templates);
  const positionNames = useSelector((state) => state.settings.positionNames);
  const formStatuses = useSelector((state) => state.forms.statuses);
  const projects = useSelector((state) => state.projects.projects);
  const [loadingStep, setLoadingStep] = useState();

  const onEmailSubmit = useCallback(({
    id, stepId, sent, stepKey,
  }) => async () => {
    if (sent || !dispatch) return;
    setLoadingStep(stepKey);
    await dispatch(resubmitEmail({ formId, stepId, id }));
    setLoadingStep();
  }, [dispatch, formId]);

  const positionIdMap = useMemo(() => getIdMap(positionNames), [positionNames]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const sortedSteps = useMemo(() => (
    steps
      .concat(
        approvals.map((approval) => ({
          ...approval,
          name: approval.state === 'approved' ? 'approval-action' : 'rejection-action',
        })),
      )
      .sort((a, b) => a.timestamp - b.timestamp)
  ), [approvals, steps]);

  const formResponseMap = useMemo(() => {
    const responseMap = {};
    sections.forEach(({ fields = [] }) => {
      fields.forEach((field) => {
        responseMap[field.id] = field.response;
      });
    });
    return responseMap;
  }, [sections]);

  const parseStep = useCallback(({
    name,
    timestamp,
    userId,
    stepId,
    metadata,
    id: workflowStepId,
    note,
  }, index) => {
    const jsonMetadata = parseMetadata(metadata);
    switch (name) {
      case 'assign': {
        const { type, userIds = [] } = jsonMetadata ?? {};
        const title = type === 'formTrigger'
          ? 'Workflow Trigger'
          : 'Form Assigned';
        let prefix = '';
        if (type === 'clockIn') {
          prefix = 'Clock In ';
        } else if (type === 'clockOut') {
          prefix = 'Clock Out ';
        } else if (type === 'import') {
          prefix = 'Imported ';
        } else if (type === 'event') {
          prefix = 'Event ';
        } else if (type === 'manual') {
          prefix = 'Manual ';
        }
        return (
          <Timeline.Item
            color="black"
            key={stepId}
          >
            <TimelineStepText
              title={`${prefix}${title}`}
              timestamp={timestamp}
              text={
                sentMessage({
                  names: formatUserIds({
                    userIds,
                    userMap,
                    defaultValue: () => 'A deleted user',
                  }),
                  text: 'assigned the form to: ',
                })
              }
            />
          </Timeline.Item>
        );
      }
      case 'resubmit':
      case 'submit':
      case 'formEditSave': {
        const { userId: metadataUserId } = jsonMetadata;
        const {
          [metadataUserId]: { name: submitter = 'Unknown User' } = {},
        } = userMap;
        let actionType = 'Submission';
        let verb = 'submitted';
        if (name === 'resubmit') {
          actionType = 'Resubmitted';
          verb = 'resubmitted';

          if (!isNullOrUndefined(jsonMetadata?.clearData)) {
            verb = jsonMetadata.clearData
              ? 'resubmitted and cleared the data in'
              : 'resubmitted and did not clear the data in';
          }
        } else if (name === 'formEditSave') {
          actionType = 'Data Updated';
          verb = 'updated the data in ';
        }
        return (
          <Step
            key={index}
            Icon={FormOutlined}
            title={`Form ${actionType}`}
            text={`${isExternalForm ? 'External User' : submitter} ${verb} the form`}
            timestamp={timestamp}
          />
        );
      }
      case 'poClose': {
        const { userId: metadataUserId } = jsonMetadata;
        const {
          [metadataUserId]: { name: submitter = 'Unknown User' } = {},
        } = userMap;
        return (
          <Step
            key={index}
            Icon={IssuesCloseOutlined}
            title="PO Closed"
            text={`${submitter} closed the PO form. Final changes may be viewed using the form snapshots feature`}
            timestamp={timestamp}
          />
        );
      }
      case 'approval-action': {
        const userText = userId in userMap ? ` by ${userMap[userId].name}` : '';
        return (
          <Step
            key={index}
            Icon={CheckCircleOutlined}
            color="green"
            title={`Form was approved${userText}`}
            timestamp={timestamp}
          />
        );
      }
      case 'rejection-action': {
        const userText = userId in userMap ? ` by ${userMap[userId].name}` : '';
        return (
          <Step
            key={index}
            Icon={CloseCircleOutlined}
            color="red"
            title={`Form was rejected${userText}`}
            timestamp={timestamp}
            note={note}
            text="Rejected"
          />
        );
      }
      case 'approval': {
        const userNames = parseUsers({
          nodeMap,
          stepId,
          userMap,
          formResponseMap,
          defaultValue: (positionId) => {
            if (!(positionId in positionIdMap)) return null;
            return <>
              - All {positionIdMap[positionId].name} users
              <br />
            </>;
          },
          metadataUsers: jsonMetadata.userIds,
        });
        return (
          <Step
            key={index}
            Icon={FileSearchOutlined}
            title="Form was sent for approval"
            text={
              sentMessage({
                text: 'sent the form to the following users for approval:',
                names: userNames,
              })
            }
            timestamp={timestamp}
          />
        );
      }
      case 'pdf': {
        const {
          [stepId]: {
            data: {
              exportLocation,
            } = {},
          } = {},
        } = nodeMap;
        const {
          exportLocation: stepLocation = exportLocation,
        } = jsonMetadata;
        return (
          <Step
            key={index}
            Icon={FilePdfOutlined}
            title="Form was saved as a PDF"
            text={(
              <div>
                {Ontraccr}
                saved the form as a PDF
                {stepLocation ? ` in ${stepLocation}` : ''}
              </div>
            )}
            timestamp={timestamp}
          />
        );
      }
      case 'push': {
        const userNames = parseUsers({ nodeMap, stepId, userMap });
        return (
          <Step
            key={index}
            Icon={MobileOutlined}
            title="Users were notifed in the mobile app"
            text={
              sentMessage({
                text: 'notified the following users on the mobile app:',
                names: userNames,
              })
            }
            timestamp={timestamp}
          />
        );
      }
      case 'emailResubmit':
      case 'email': {
        const isResubmit = name === 'emailResubmit';
        let emailList = [];
        let ccList = [];
        let bccList = [];
        let sent = false;
        let didFail = false;
        try {
          const defaultMeta = metadata || '{}';
          const {
            emails = [],
            bcc = [],
            cc = [],
            resubmitted = false,
            failed = false,
          } = JSON.parse(defaultMeta);
          emailList = emails;
          ccList = cc;
          bccList = bcc;
          sent = resubmitted;
          didFail = failed;
        } catch (err) {
          // Do nothing
        }
        const title = sent ? '✓' : 'Resend Email';
        const stepKey = `${stepId}-${timestamp}`;

        const failVerb = isResubmit ? 'resend' : 'send';
        const successVerb = isResubmit ? 'resent' : 'sent';

        const stepTitle = didFail
          ? `Ontraccr failed to ${failVerb} an email`
          : `Form was ${successVerb} in an email`;
        return (
          <Step
            key={stepKey}
            Icon={MailOutlined}
            title={stepTitle}
            color={didFail ? 'red' : 'black'}
            textColor={didFail ? 'red' : null}
            text={
              didFail
                ? null
                : sentMessage({
                  text: ' sent a PDF version of the form in an email to',
                  names: (
                    <div>
                      { emailList.map(getEmailList) }
                      { !!ccList.length && (
                        <div>
                          CC:
                          <br />
                          { ccList.map(getEmailList) }
                        </div>
                      )}
                      { !!bccList.length && (
                        <div>
                          BCC:
                          <br />
                          { bccList.map(getEmailList) }
                        </div>
                      )}
                    </div>
                  ),
                })
            }
            timestamp={timestamp}
            action={{
              onClick: onEmailSubmit({
                sent,
                stepKey,
                stepId,
                id: workflowStepId,
              }),
              title,
              loading: loadingStep === stepKey,
            }}
          />
        );
      }
      case 'formUpdate': {
        const {
          templateId,
          formToUpdateTemplateId,
          userId: callerId,
          shouldOverrideForm,
          oldData,
          newData,
        } = jsonMetadata;

        const {
          [callerId]: {
            name: userName = '',
          } = {},
        } = userMap;

        const {
          [templateId]: {
            name: templateName = '',
          } = {},
          [formToUpdateTemplateId]: {
            name: updatedTemplateName = '',
          } = {},
        } = templates;

        return (
          <Step
            key={index}
            Icon={FormOutlined}
            title="Form Update"
            text={
              sentMessage({
                text: (
                  <span>
                    &nbsp;submitted&nbsp;
                    {bold(templateName)}
                    form
                    {shouldOverrideForm ? ' overriding the data in ' : ' appending data to '}
                    form&nbsp;
                    {bold(updatedTemplateName)}
                  </span>
                ),
                caller: userName,
              })
            }
            timestamp={timestamp}
          >
            <FormUpdateStep
              oldData={oldData}
              newData={newData}
            />
          </Step>
        );
      }
      case 'formTrigger': {
        const {
          userIds: metadataUsers = [],
          sharedForm,
          templateId,
        } = jsonMetadata;
        const userNames = parseUsers({
          nodeMap,
          stepId,
          userMap,
          metadataUsers,
          formResponseMap,
          defaultValue: (positionId) => {
            if (!(positionId in positionIdMap)) return null;
            return <>
              - All {positionIdMap[positionId].name} users
              <br />
            </>;
          },
        });
        const {
          [stepId]: {
            data: {
              form,
            } = {},
          } = {},
        } = nodeMap;

        // Unfortunately we cannot use the templateId for everything because past forms
        // Will not have this in the metadata
        const relevantTemplateId = form?.startsWith('field-')
          ? templateId
          : form;

        const defaultValue = sharedForm ? '' : 'a';
        const {
          [relevantTemplateId]: {
            name: templateName = defaultValue,
          } = {},
        } = templates;

        return (
          <Step
            key={index}
            Icon={FormOutlined}
            title="Form Trigger"
            text={
              sentMessage({
                text: <span> initiated {sharedForm ? 'a shared' : ''} {bold(templateName)} form {userNames.length ? 'for' : ''} </span>,
                names: userNames,
              })
            }
            timestamp={timestamp}
          />
        );
      }
      case 'dispatch': {
        const {
          [stepId]: {
            data: {
              fieldMappings = {},
            } = {},
          } = {},
        } = nodeMap;
        const {
          title: titleFieldId,
          userIds: userFieldId,
        } = fieldMappings;
        const {
          [titleFieldId]: {
            value: shiftTitle = '',
          } = {},
          [userFieldId]: {
            values: users = [],
          } = {},
        } = formResponseMap;

        const {
          multi,
          shiftTitles = [shiftTitle],
        } = jsonMetadata;

        const safeShiftTitles = shiftTitles ?? [shiftTitle];
        const { userIds: metadataUsers } = jsonMetadata;
        const ourUsers = (
          metadataUsers
            ? metadataUsers.map((mId) => userMap[mId])
            : users
        );
        const numShifts = safeShiftTitles.length;
        const suffix = numShifts === 1 ? ' was' : 's were';
        const stepTitlePrefix = multi ? numShifts : 'A';

        const namesList = multi && numShifts > 1
          ? shiftTitles
          : ourUsers.map((user) => user.name);
        const text = multi && numShifts > 1
          ? <span> scheduled the following shifts: </span>
          : <span> scheduled a {bold(shiftTitle)} shift for </span>;

        return (
          <Step
            key={index}
            Icon={CalendarOutlined}
            title={`${stepTitlePrefix} Shift${suffix} scheduled`}
            text={
              sentMessage({
                text,
                names: namesList.map((name) => (
                  <>
                  {`- ${name}`}
                  <br />
                  </>
                )),
              })
            }
            timestamp={timestamp}
          />
        );
      }
      case 'updateContract': {
        const {
          [stepId]: {
            data: {
              fieldMappings = {},
            } = {},
          } = {},
        } = nodeMap;
        const {
          contractAmount: contractAmountFieldId,
          description: descriptionFieldId,
        } = fieldMappings;
        const {
          [contractAmountFieldId]: {
            value: changeOrderContractAmount = 0,
          } = {},
          [descriptionFieldId]: {
            value: changeOrderDescription = '',
          } = {},
        } = formResponseMap;
        return (
          <Step
            key={index}
            Icon={DollarOutlined}
            title="Change Order was created"
            text={(
              <div>
                {Ontraccr} created a change order: {changeOrderDescription} with contract amount: {currencyFormatter(changeOrderContractAmount)}
              </div>
            )}
            timestamp={timestamp}
          />
        );
      }
      case 'status': {
        const {
          [stepId]: {
            data: {
              statusId,
            } = {},
          } = {},
        } = nodeMap;
        const {
          [statusId]: { status } = {},
        } = formStatuses;
        return (
          <Step
            key={index}
            Icon={TagOutlined}
            title="Status was updated"
            text={
              <div>
                {Ontraccr} updated the status of the form{status ? ' to ' : ''} {bold(status)}
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'edit': {
        const userNames = parseUsers({
          formResponseMap,
          nodeMap,
          stepId,
          userMap,
          defaultValue: (positionId) => {
            if (!(positionId in positionIdMap)) return null;
            return <>
              - All {positionIdMap[positionId].name} users
              <br />
            </>;
          },
        });
        return (
          <Step
            key={index}
            Icon={EditOutlined}
            title="Form Edit Requested"
            text={
              sentMessage({
                text: 'sent the form to the following users for edits:',
                names: userNames,
              })
            }
            timestamp={timestamp}
          />
        );
      }
      case 'edited': {
        const { editorId } = JSON.parse(metadata);
        return (
          <Step
            key={index}
            Icon={EditFilled}
            title="Form Edited"
            text={
              <div>
                Form edited {userMap[editorId] ? `by ${userMap[editorId].name}` : ''}
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'qbInvoice':
      case 'qbPO': {
        return (
          <Step
            key={index}
            Icon={CloudSyncOutlined}
            title="Invoice was synced with QuickBooks"
            text={
              <div>
                {Ontraccr} synced the {name === 'qbInvoice' ? 'Invoice' : 'PO'} data into QuickBooks
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'qbInvoicePaid': {
        return (
          <Step
            key={index}
            Icon={DollarOutlined}
            title="Invoice was marked as paid in QuickBooks"
            timestamp={timestamp}
          />
        );
      }
      case 'ontraccrInvoicePaid': {
        return (
          <Step
            key={index}
            Icon={DollarOutlined}
            title="Invoice was marked as paid in Ontraccr"
            text={
              <div>
                {Ontraccr} updated the invoice data in QuickBooks
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'stripePayment': {
        const defaultMeta = metadata || '{}';
        const { email } = JSON.parse(defaultMeta);
        const emailText = email ? ` to ${email}` : '';
        return (
          <Step
            key={index}
            Icon={DollarOutlined}
            title="Payment link was sent"
            text={
              <div>
                {Ontraccr} sent a payment link{emailText}
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'stripePaymentPaid': {
        return (
          <Step
            key={index}
            Icon={DollarOutlined}
            title="Payment received"
            timestamp={timestamp}
          />
        );
      }
      case 'materialDebit': {
        return (
          <Step
            key={index}
            Icon={GoldOutlined}
            title="Materials Updated"
            text={
              <span>{Ontraccr} updated materials in your materials database</span>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'cancel': {
        const { userId: cancelUser } = jsonMetadata;
        const userText = cancelUser in userMap ? ` by ${userMap[cancelUser].name}`: '';
        return (
          <Step
            key={index}
            Icon={StopOutlined}
            color="red"
            title={`Workflow was cancelled${userText}`}
            timestamp={timestamp}
            prefixCls="ot-timeline-cancel"
          />
        );
      }
      case 'logicalYesNo': {
        const { logicalValue, fieldName, sectionName, hasChild } = jsonMetadata;
        return (
          <Step
            key={index}
            Icon={BranchesOutlined}
            title="Logical Yes/No block was evaluated"
            text={
              <div>
                {bold(sectionName)} - {bold(fieldName)} was evalulated and the result was {bold(logicalValue)}.
                {hasChild ? 'The next step was triggered.' : ''}
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'logicalLoop': {
        const { valuesToLoop = [], fieldName = 'field', sectionName = 'section' } = jsonMetadata;
        return (
          <Step
            key={index}
            Icon={RetweetOutlined}
            title="Logical Loop block was evaluated"
            text={
              <div>
                {bold(sectionName)} - {bold(fieldName)} was evalulated and was looped: {bold(valuesToLoop.length)} times.
                { valuesToLoop.length ? 'The next step was triggered on the following values:' : ''}
                { valuesToLoop.map(({ id, name }) => (
                  <div key={id}>
                    {`- ${name}`}
                    <br />
                  </div>
                ))}
              </div>
            }
            timestamp={timestamp}
          />
        );
      }
      case 'reassign': {
        const { userId, addedUsers, removedUsers } = jsonMetadata;
        const userText = userId in userMap ? bold(userMap[userId].name): '';

        return (
          <Step
            key={index}
            Icon={BranchesOutlined}
            title="Form Reassigned"
            timestamp={timestamp}
            text={
              sentMessage({
                caller: userText,
                text: 'reassigned the form',
                names: (
                  <div>
                    { !!addedUsers.length && (
                      <div>
                        Added:
                        <br />
                        { formatUserIds({ userIds: addedUsers, userMap }) }
                      </div>
                    )}
                    { !!removedUsers.length && (
                      <div>
                        Removed:
                        <br />
                        { formatUserIds({ userIds: removedUsers, userMap }) }
                      </div>
                    )}
                  </div>
                ),
              })
            }
          />
        );
      }
      case 'createProfile': {
        const { subType } = jsonMetadata;
        return (
          <Step
            key={index}
            Icon={ProjectFilled}
            title="Create Profile"
            timestamp={timestamp}
            text={
              <div>A {subType} Profile was created</div>
            }
          />
        );
      }
      case 'createBoardCard': {
        const {status, board} = jsonMetadata;
        const statusText = status && board ? <span>With status {statusTitle(board, status)}</span> : '';
        return (
          <Step
            key={index}
            Icon={GroupOutlined}
            title="Board Card Created"
            timestamp={timestamp}
            text={
              <div>{statusText}</div>
            }
          />
        );
      }
      case 'photoRotation': {
        const { imageName, userName: userNameRotate } = jsonMetadata;
        return (
          <Step
            key={index}
            Icon={RotateRightOutlined}
            title="Photo Rotated"
            timestamp={timestamp}
            text={
              <div>{`${imageName} rotated by ${userNameRotate}`}</div>
            }
          />
        );
      }
      case 'updateBoardCard': {
        const { status, board, cardIdsToUpdate = [] } = jsonMetadata;
        const cardsText = ` ${cardIdsToUpdate.length} ${cardIdsToUpdate.length === 1 ? 'card ' : 'cards '}`;
        const updateText = (
          <span>
            Updated
            {cardsText}
            {status && board ? (
              <span>
                {'with status '}
                {statusTitle(board, status)}
              </span>
            ) : ''}
          </span>
        );
        return (
          <Step
            key={index}
            Icon={GroupOutlined}
            title="Board Card Updated"
            timestamp={timestamp}
            text={
              <div>{updateText}</div>
            }
          />
        );
      }
      case 'updateCost': {
        const { projectId, isBudgetUpdate } = jsonMetadata;
        const projectName = projectMap?.[projectId]?.name ?? 'Unknown';
        const pageName = isBudgetUpdate ? 'Budget' : 'Progress';
        const fullText = `Updated ${projectName}'s ${pageName} Page`;
        const updateText = <span>{fullText}</span>;
        return (
          <Step
            key={index}
            Icon={DollarOutlined}
            title="Project Cost Updated"
            timestamp={timestamp}
            text={
              <div>{updateText}</div>
            }
          />
        );
      }
      case 'externalSignature': {
        const {
          failed,
          signers,
          errorMessage,
        } = jsonMetadata;
        const stepKey = `${stepId}-${timestamp}`;

        const stepTitle = failed
          ? 'Ontraccr failed to create the Docusign envelope'
          : 'Envelope was created in Docusign';
        return (
          <Step
            key={stepKey}
            Icon={MailOutlined}
            title={stepTitle}
            color={failed ? 'red' : 'black'}
            textColor={failed ? 'red' : null}
            text={
              failed
                ? errorMessage
                : sentMessage({
                  caller: bold('Docusign'),
                  text: ' sent an envelope for signing to:',
                  names: signers
                    .sort(((a, b) => a.order - b.order))
                    .map(({ email, order }) => getEmailList(email, order)),
                })
            }
            timestamp={timestamp}
          />
        );
      }
      case 'createTime': {
        const {
          autoSubmit,
          autoApprove,
          errors: {
            conflictErrors = [],
            parsedInsertErrors = [],
          } = {},
        } = jsonMetadata;
        const hasSubtext = autoSubmit || autoApprove;
        const autoApproveText = ' and approved';
        const subtext = `submitted${autoApprove ? autoApproveText : ''}`;
        const hasErrors = !!conflictErrors?.length || !!parsedInsertErrors?.length;
        const subText = hasSubtext
          ? (
            <div>
              {Ontraccr} automatically {subtext} the time entries
            </div>
          )
          : null;
        const errorText = hasErrors
          ? (
            <>
              { !!conflictErrors?.length && (
                <div style={{ color: 'red', marginTop: subText ? 5 : 0, fontFamily: 'roboto-regular' }}>
                  The following entries conflicted with existing time entries and were not created:
                  {
                    conflictErrors.map((err, errIndex) => (
                      <div style={{ marginLeft: 5 }}>
                        {'- '}{err}
                        {errIndex < conflictErrors.length - 1 && <br />}
                      </div>
                    ))
                  }
                </div>
              )}
              { !!parsedInsertErrors?.length && (
                <div style={{ color: 'red', marginTop: subText ? 5 : 0, fontFamily: 'roboto-regular' }}>
                  The following time entries were not created due to errors:
                  {
                    parsedInsertErrors.map((err, errIndex) => (
                      <div style={{ marginLeft: 5 }}>
                        {'- '}{err}
                        {errIndex < parsedInsertErrors.length - 1 && <br />}
                      </div>
                    ))
                  }
                </div>
              )}
            </>
          ) : null;
        return (
          <Step
            key={index}
            Icon={ClockCircleOutlined}
            title="Time entries created"
            timestamp={timestamp}
            text={(
              <>
                {subText}
                {errorText}
              </>
            )}
          />
        );
      }
      default:
        // Shouldnt reach this
        return (
          <Step
            key={index}
            Icon={MailOutlined}
            title={name}
            timestamp={timestamp}
          />
        );
    }
  }, [userName, userMap, nodeMap, dispatch, loadingStep, onEmailSubmit, isExternalForm]);

  const assignedNameView = useMemo(() => (
    assignedUserNames.map((assignedName) => (
      <>
        {`- ${assignedName}`}
        <br />
      </>
    ))
  ), [assignedUserNames]);

  const hasAssigned = useMemo(() => (
    steps.some((step) => step?.name === 'assign')
  ), [sortedSteps]);

  return (
    <div className="form-timeline-container">
      <Timeline
        style={{ paddingLeft: 20, paddingTop: 10 }}
        pending={<PendingView isFinished={isFinished} />}
        pendingDot={<PendingDot isFinished={isFinished} />}
      >
        {
          !hasAssigned
          && (
          <Timeline.Item
            color="black"
          >
            <TimelineStepText
              title="Workflow Trigger"
              timestamp={createdAt}
              text={
                sentMessage({
                  names: isExternalForm ? 'External User' : assignedNameView,
                  text: 'assigned the form to: ',
                })
              }
            />
          </Timeline.Item>
          )
        }
        {sortedSteps.map(parseStep)}
        {
        formState === 'Cancelled'
          ? null
          : <CompleteStep isFinished={isFinished} />
        }
      </Timeline>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table } from 'antd';

const { Text } = Typography;

/** Form Table Component */
export default function FormsTable({
  title,
  data,
  columns,
  onClickRow,
  onExpand,
  expandable,
  loading = false,
  style = {},
}) {
  return (
    <>
      <Text style={{ fontFamily: 'roboto-medium', fontSize: 18, ...style }}>{ title }</Text>
      <div className="file-list-container">
        <Table
          style={{ height: '100%' }}
          scroll={{ y: 'calc(100vh - 228px)' }}
          dataSource={data}
          columns={columns}
          pagination={false}
          size="small"
          rowClassName="file-table-row"
          onRow={(record) => ({
            onClick: () => onClickRow(record),
          })}
          rowKey="id"
          expandable={expandable}
          onExpand={onExpand}
          loading={loading}
        />
      </div>
    </>
  );
}

FormsTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    templateId: PropTypes.string,
    templateName: PropTypes.string,
    createdAt: PropTypes.number,
    lastUpdated: PropTypes.number,
    users: PropTypes.arrayOf(PropTypes.string), // array of user id
    status: PropTypes.string,
    companyId: PropTypes.string,
  })),
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    dataIndex: PropTypes.string,
    width: PropTypes.number,
    align: PropTypes.string,
  })),
  onClickRow: PropTypes.func.isRequired,
  onExpand: PropTypes.func,
  expandable: PropTypes.shape({
    expandedRowRender: PropTypes.func,
    rowExpandable: PropTypes.func,
  }),
  loading: PropTypes.bool,
  style: PropTypes.shape({}),
};

FormsTable.defaultProps = {
  title: '',
  data: [],
  columns: [],
  onExpand: () => {},
  expandable: {},
  loading: false,
  style: {},
};

import React from 'react';
import { Row, Col, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

export default function SubtasksHeader({
  onAddClicked,
  onSearch,
}) {
  const onSearchChanged = (e) => {
    const {
      target: { value } = {},
    } = e;
    onSearch(value);
  };

  return (
    <Row justify="start" style={{ marginBottom: 14 }} gutter={20}>
      <Col>
        <Input.Search
          type="search"
          style={{ width: 250 }}
          className="searchbar"
          placeholder="Search"
          allowClear
          onChange={onSearchChanged}
        />
      </Col>
      <Col>
        {onAddClicked && (
          <OnTraccrButton
            icon={<PlusOutlined />}
            title="Add"
            onClick={onAddClicked}
          />
        )}
      </Col>
    </Row>
  );
}
